* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: -webkit-fill-available;
  scroll-behavior: smooth;
}

body {
  padding-right: 0 !important;
  font-family: "Poppins", sans-serif;
  color: var(--base03);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  background-color: var(--base-white-bg);
}

ol,
ul {
  padding-left: 0;
  margin-bottom: 0;
}

.roman_list_type {
  list-style-type: upper-roman;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

.datepickerbar {
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Colors Variables */
:root {
  --base00: #e31b54;
  --base01: #667085;
  --base02: #000000;
  --base03: #344054;
  --form-placeholder: #98a2b3;
  --base-border: #d0d5dd;
  --form-input: #101828;
  --base-error: #f04438;
  --base-green: #acdc79;
  --base-note: #e0f2fe;
  --base-note-text: #026aa2;
  --base-white: #ffffff;
  --or-after: #eaecf0;
  --homecomponent-bg: #fcfcfd;
  --icons-bg: #e6f4d7;
  --icons-color: #4f7a21;
  --teritory-text: #c01048;
  --teritory-bg: #fff1f3;
  --teritroy-bghover: #ffe4e8;
  --teritory-active: #ffccd6;
  --teritory-focus: #fff1f3;
  --lightgreen-bg: #fafdf7;
  --green-border: #ceeab0;
  --total-greenbg: #86cb3c;
  --btn-pinkhover: #f63d68;
  --states-bg: #f5fbee;
  --states-head: #669f2a;
  --states-subhead: #3f621a;
  --testi-bg: #f0f9ff;
  --error-border: #fda29b;
  --bg-light-lg: #fff5f6;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  transition: 0.1s ease;
  cursor: pointer;
}

.form-control {
  text-transform: normal;
  width: 100%;
  background: var(--base-white);
  color: var(--form-input);
  border: 1px solid var(--base-border);
  border-radius: 8px;
  width: 100%;
  padding: 12px 14px 12px 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  height: auto;
  transition: 0.1s ease;
}

.form-control::placeholder {
  color: var(--form-placeholder);
}

.form-control {
  color: var(--base03);
}

.form-control:focus,
.form-control:focus-visible {
  background: var(--base-white) !important;
  border: 1px solid #363f72 !important;
  box-shadow: 0px 1px 2px rgb(62 71 132 / 5%), 0px 0px 0px 4px #eaecf5 !important;
}

.error .form-control {
  background: var(--base-white) !important;
  border: 1px solid var(--error-border) !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fee4e2 !important;
}

.error.form-control {
  background: var(--base-white) !important;
  border: 1px solid var(--error-border) !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fee4e2 !important;
}

body .error .flag-dropdown {
  border: 1px solid var(--error-border) !important;
  border-right: 0 !important;
}

.error-line {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #f04438;
  margin-top: 5px;
}

.formgroup {
  position: relative;
}

.form-control[type="date"] {
  padding-left: 40px;
}

.form-control[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 15px;
  filter: opacity(0.4);
}

/* Checkboxes */
input[type="checkbox"] {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  background-color: var(--base-white);
  border: 1px solid var(--base-border);
  border-radius: 4px !important;
  margin-bottom: 0;
  cursor: pointer;
  box-shadow: none;
  transition: 0.5s ease;
}

input[type="checkbox"]:checked[type="checkbox"] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABwSURBVHgBhY3BDUBAEEX368DVhihJMu6UoBMloAA9yeKuAmOWSNhkd1/yLzMv/0NF2DIaTuBIYhIDLRQjKK26ZqNpvA9G171NUBJkmlPp7TZNw3eOFU/FPreviKeVRig0kkWE0pV+WNmd82Jyqny/C4tjNaBZIxy4AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 3px 4px;
  background-size: 8px;
  box-shadow: none;
}

input[type="checkbox"]:focus {
  border: 1px solid #fea3b4 !important;
  box-shadow: none !important;
}

input[type="checkbox"]:hover {
  background: var(--teritroy-bghover);
  border: 1px solid var(--base00);
}

input[type="checkbox"]:checked {
  background: var(--teritory-bg);
  border: 1px solid var(--base00);
  box-shadow: none !important;
}

.gbtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--base-white);
  border: 1px solid var(--base-border);
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  padding: 10px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--base03);
  transition: 0.1s ease;
}

.btn {
  transition: 0.1s ease;
  text-decoration: none;
  cursor: pointer;
}

.btngray {
  padding: 12px 20px;
  outline: none;
  background: var(--base-white) !important;
  border: 1px solid var(--base-border) !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  height: 48px;
  color: var(--base03) !important;
  width: 100%;
  display: block;
  transition: 0.1s ease;
}

.btn-pink.contribute-now {
  justify-content: center !important;
}

.btn-cancelbar.btngray,
.btn-cancelbar.btngray:hover {
  background: rgba(255, 241, 243, 1) !important;
  border-color: rgba(255, 241, 243, 1) !important;
  color: #c01048 !important;
}

.btngray:hover {
  background: #f9fafb !important;
  border: 1px solid var(--base-border) !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
}

.btngray:active {
  background: var(--or-after) !important;
  border: 1px solid var(--base-border) !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.btngray:focus,
.btngray:focus-visible,
.btngray[disabled] {
  background: var(--base-white);
  border: 1px solid var(--base-border);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
}

.btn-pink {
  background-color: var(--base00);
  border: 1px solid var(--base00);
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  color: var(--base-white);
  font-size: 16px;
  line-height: 16px;
  outline: none;
  font-weight: 500;
  text-decoration: none;
  transition: 0.1s ease-in;
}

.btn_outline {
  background-color: var(--base-white);
  border: 1px solid var(--base00);
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  color: var(--base00);
  font-size: 16px;
  line-height: 16px;
  outline: none;
  font-weight: 500;
  text-decoration: none;
  transition: 0.1s ease-in;
}

.btn_outline:hover {
  background-color: var(--base00);
  color: var(--base-white);
}

.btn-pink:hover {
  background: var(--btn-pinkhover);
  border-color: var(--teritory-text);
  color: var(--base-white);
}

.btn-pink:focus,
.btn-pink:focus-visible {
  background: var(--base00);
  border-color: var(--base00) !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%),
    0px 0px 0px 4px var(--teritroy-bghover);
}

.btn-pink:active {
  color: var(--base-white) !important;
  background-color: var(--teritory-text) !important;
  border-color: var(--teritory-text) !important;
}

.btn-pink[disabled] {
  background: var(--teritory-active);
  border: 1px solid var(--teritory-active);
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  cursor: no-drop;
}

.btn-Tertiary {
  background: var(--teritory-bg);
  border: 1px solid var(--teritory-bg);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  color: var(--teritory-text) !important;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.1s ease-in;
}

.btn-Tertiary:hover {
  background: var(--teritroy-bghover);
  border: 1px solid var(--teritroy-bghover);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.btn-Tertiary:active {
  background: var(--teritory-active) !important;
  border: 1px solid var(--teritory-active) !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
}

.btn-Tertiary:focus {
  background: var(--teritory-focus) !important;
  border: 1px solid var(--teritory-focus) !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ffe4e8 !important;
}

.btn-Tertiary[disabled] {
  background: var(--bg-light-lg);
  border: 1px solid var(--bg-light-lg);
  color: #fea3b4;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.btn-pink .arrow-icon {
  margin-left: 5px;
}

.btn-pink .arrow-icon {
  margin-left: 5px;
}

.toprow .titleevent {
  font-weight: 600;
}

.toprow .participant-list-ul .detail-contribution h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
}

.toprow .participant-list-ul .detail-contribution p {
  font-size: 12px;
}

.toprow .participant-list-ul li.revokeli {
  border-bottom: 1px solid var(--base-border);
}

.yettocontribute {
  margin-top: 50px;
}

.yettocontribute .titleevent,
.yettocontribute .detail-contribution {
  opacity: 0.3;
}

.yettocontribute {
  margin-top: 50px;
}

.toprow .participant-list-ul .btnbar h4 {
  margin: 0;
  color: var(--base-green);
  font-weight: 400;
  font-size: 18px;
}

.amount-received {
  margin-bottom: 20px;
}

.amount-received .amountbox {
  padding: 20px;
  border: 1px solid var(--base-green);
  border-radius: 15px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
}

.amount-received h5 {
  text-align: center;
  font-size: 14px;
  color: gray;
  margin: 0;
}

.amount-received h5 span {
  color: var(--base-green);
}

.amount-received .amountbox span {
  font-size: 14px;
  font-weight: 300;
}

.fixamountpopup .FixedAmount h4 {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #344054;
  text-align: left !important;
  margin-top: 10px;
}

.yettocontribute .btnbar h4 {
  color: var(--base02) !important;
}

.noheader {
  padding: 20px;
  height: 100vh;
}

.buttonfactor .btn-pink {
  margin-bottom: 15px;
}

.fixamountpopup .FixedAmount p {
  text-align: center;
  margin-bottom: 20px;
}

.noheader .onboardbar {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 70px;
}

.fixamountpopup .modal-header h6 {
  text-align: left;
  margin: 0;
  width: 100%;
}

.fixamountpopup .modal-body .FixedAmount h4 {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 30px;
  text-align: center;
}

.noheader .onboardbar .onboard-head .logobar {
  text-align: center;
  margin-bottom: 80px;
}

.onboard-head {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.noheader .onboardbar .onboard-head .peoplegroup {
  margin-bottom: 80px;
  text-align: center;
}

.noheader .onboardbar .onboard-head .teamup-content h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: var(--base02);
  margin-bottom: 24px;
  text-align: left;
}

.noheader .onboardbar .started-btn {
  width: 100%;
}

a.see_more_anchor {
  cursor: pointer;
}

.wishes_div {
  word-wrap: break-word;
}

.Headerbar {
  width: 100%;
  padding: 56px 24px 20px 24px;
  position: relative;
}

.min-height-settle .card_body {
  min-height: 190px !important;
  max-height: 190px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Headerbar ul h6 {
  margin: 0;
  font-size: 12px;
}

.home-wrapper .homebar ul li.logobar a.brand {
  display: inline-flex !important;
  gap: 6px;
  align-items: flex-end;
  justify-content: flex-start;
}

.home-wrapper .homebar ul li.logobar a.brand h6 {
  color: #ffffff;
  font-size: 10px;
}

.Headerbar ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  gap: 10px;
}

.Headerbar ul h6 {
  margin: 0;
  font-size: 12px;
}

.otpinputs .otpbar input::placeholder {
  color: var(--base-border);
}

/* .OnboardSlider .carousel .carousel-item .peoplegroup , .OnboardSlider .carousel .carousel-item .carousel-caption {
    transform: translate(0px, -20px);
    opacity: 0;
    transition: .5s ease;
}
.OnboardSlider .carousel .carousel-item.active .peoplegroup , .OnboardSlider .carousel .carousel-item.active .carousel-caption {
    transform: translate(0px, 0px);
    opacity: 1;
} */
.Headerbar ul a.backtobasic {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--base-border);
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--base03);
}

.btnskip {
  background: var(--base-white);
  border: 1px solid var(--base-border);
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--base03);
  display: none;
}

[data-page="/login"] .btnskip,
[data-page="/enterotp"] .btnskip,
[data-page="/infofill"] .btnskip {
  display: block;
}

.body-wrapper {
  padding: 10px 24px 24px 24px;
  position: relative;
}
body:has(#organiser_choose_event_type) .body-wrapper {
  position: static;
}

#organiser_choose_event_type {
  position: absolute;
  top: 63px;
  right: 24px;
  display: flex;
  flex-direction: row;
}
.max-width-window {
  position: relative;
}

.loginform h4.form-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--base03);
  margin-bottom: 24px;
}

.loginform h4.form-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--base03);
  margin-bottom: 24px;
}

label.form-label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
  margin-bottom: 5px;
}

.formgroup {
  margin-bottom: 12px;
}

.form-divider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 24px;
}

.form-divider span {
  background: var(--base-white);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base03);
  padding: 0 20px;
  z-index: 1;
}

.form-divider::after {
  content: "";
  width: 100%;
  height: 1px;
  background: var(--or-after);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0px, -50%);
  z-index: 0;
}

.formgroup .react-tel-input input.form-control {
  width: 100%;
  background: var(--base-white);
  color: var(--form-input);
  border: 1px solid var(--base-border);
  border-radius: 8px;
  width: 100%;
  padding: 12px 14px 12px 55px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  height: auto;
}

.otpinputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otpinputs .otpbar:first-child {
  margin: 0 3px 0 0;
}

.otpinputs .otpbar:last-child {
  margin: 0 0 0 3px;
}

.otpinputs .otpbar {
  width: 75px;
  border: 1px solid var(--base-border);
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  outline: none;
  padding: 10px;
  height: 60px;
  margin: 0 3px;
}

.otpinputs div {
  width: 100%;
  justify-content: space-between;
}

.otpinputs .otpbar input {
  cursor: pointer;
  width: 100% !important;
  height: auto;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  font-weight: 500;
  font-size: 42px;
  line-height: 43px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--base03);
}

.otpbar.error input {
  background: var(--base-white);
  border: 1px solid var(--error-border);
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 4px #fee4e2;
}

.otpbar.error {
  background: var(--base-white);
  border: 1px solid var(--error-border);
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 4px #fee4e2;
}

.otpinputs .otpbar span {
  display: none;
}

.uploadmedia.imageuploaded {
  position: relative;
}

.uploadmedia.imageuploaded input.uploadinput {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.otpinputs .otpbar:focus {
  background: var(--base-white);
  border: 1px solid #363f72;
  box-shadow: 0px 1px 2px rgba(62, 71, 132, 0.05), 0px 0px 0px 4px #eaecf5;
}

.otpinputs .form-control {
  padding: 10px !important;
  width: 78px !important;
  height: 64px !important;
  text-align: center;
  font-size: 48px !important;
  line-height: 1 !important;
  font-weight: 600 !important;
}

.eventbar textarea {
  text-transform: none;
  height: 90px !important;
  font-size: 14px !important;
  line-height: 18px;
}

.EventCancelState .event-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-bottom: 12px;
}

.btn-cancel {
  background: #ffffff;
  border: 1px solid #fda29b !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  color: #b42318 !important;
}

.btn-cancel:hover {
  border: 1px solid #fda29b !important;
  background: #b42318 !important;
  color: #ffffff !important;
}

.EventCancelState p.note-error {
  justify-content: center;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #f04438;
}

.cancelevent-modal .modal-header {
  padding: 20px 20px 0 0 !important;
  position: relative;
  z-index: 2;
}

.cancelevent-modal .modal-body .del-icon {
  padding: 0 !important;
  text-align: center;
  width: 48px;
  height: 48px;
  background: #fee4e2;
  border: 8px solid #fef3f2;
  border-radius: 28px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.Createevent-modal.cancelevent-modal .modal-body {
  padding: 0 20px !important;
  margin-top: -15px;
}

.cancelevent-modal .modal-body .del-icon img {
  width: 16px !important;
}

.trash-icon {
  height: 20px;
  max-width: 100%;
  cursor: pointer;
}

.react-tel-input .flag-dropdown {
  background: transparent !important;
  border: none !important;
  border-radius: 8px 0 0px 8px !important;
  border-right: none !important;
}

.cancelevent-modal .modal-body h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--form-input);
  margin-bottom: 8px;
}

.cancelevent-modal .btn-red {
  background: #d92d20 !important;
}

.cancelevent-modal .modal-body p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--base01);
}

.Eventcannot-modal {
  background: rgb(0 0 0 / 44%);
}

/* .Createevent-modal .modal-content {
  min-height: 300px;
} */

.react-tel-input .selected-flag {
  width: 50px !important;
  padding: 0 0 0 15px !important;
  border-radius: 8px 0px 0px 8px !important;
}

.formgroup .react-tel-input input.form-control:focus ~ .flag-dropdown {
  border: 1px solid #363f72;
  border-right: none;
}

.gbtn img {
  margin-right: 12px;
}

.resend-otp {
  margin-top: 12px;
  text-align: center;
}

/* .timerbtn,
.how-attempt {
    display: none;
} */
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

.react-tel-input .selected-flag:hover {
  background: transparent !important;
}

.btngray .timer {
  color: var(--form-placeholder);
}

.timerbtn {
  max-width: 60%;
  margin: 0 auto;
}

.homebar {
  padding: 56px 24px 24px 24px;
  position: relative;
}

.cookiesbar {
  text-align: center;
}

.cookiesbar .iconsbar {
  margin-bottom: 10px;
}

.cookiesbar .iconsbar svg {
  font-size: 40px;
}

.cookiesbar h4 {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: var(--base03);
}

.cookiesbar p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--base01);
}

.modal-content {
  border: none;
  border-radius: 0.5rem 0.5rem 0 0;
}

.homebar {
  overflow: hidden;
}

.homebar::after {
  content: "";
  width: 100%;
  background: var(--base00);
  position: absolute;
  top: 0;
  left: 0;
  height: 350px;
  z-index: -11;
  border-radius: 0px 0px 24px 24px;
}

.home-wrapper .homebar ul {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  /* z-index: 2; */
}

.home-wrapper .homebar ul li.logobar a.brand {
  display: inline-block;
  margin-bottom: 0;
}

.home-wrapper .homebar ul li.logobar p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--base-white);
}

.home-wrapper .homebar ul li.userbar button {
  background: var(--teritroy-bghover);
  border-radius: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--base00);
  font-size: 24px;
  cursor: pointer;
  transition: 0.5s ease;
  border: none;
}

.connection-card-div .notification-profile-pic {
  border-radius: 50% !important;
  object-fit: cover;
  max-width: 94px !important;
  height: 94px;
  width: 94px;
}

.add-connection-modal .modal-body {
  flex: 1;
  overflow: auto;
}
.add-connection-modal .modal-content {
  height: 100%;
}

.connection-card-img {
  max-width: 64px;
  height: 64px;
  width: 100%;
}

.connection-card-div .celebration-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 28px;
  width: 28px;
}

.connection-card-div .notification-info h5 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.connection-search-div {
  padding: 0 20px 10px 20px;
}

.connection-card-div .notification-info p {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.notification-tabs {
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: space-evenly;
  margin: 24px 0px;
}

.time-period-div.selected h5 {
  color: var(--Primary-700, #c01048);
  /* Text lg/Medium */
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.time-period-div.selected {
  border-radius: 8px;
  border: 1px solid var(--Primary-50, #fff1f3);
  background: var(--Primary-50, #fff1f3);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

input.phone-input {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  left: 90px;
  border: 1px solid var(--base-border);
  border-radius: 8px;
  text-align: center;
}

.time-period-div {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.time-period-div h5 {
  color: var(--Gray-500, #667085);
  /* Text lg/Medium */
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  margin: 0px;
}

.home-wrapper .homebar ul li.userbar {
  display: flex;
  gap: 12px;
}

.home-wrapper .homebar ul li.userbar svg {
  transition: 0.5s ease;
}

.home-wrapper .homebar ul li.userbar:hover svg {
  transform: scale(1.1);
}

.Home-component {
  background-color: var(--homecomponent-bg);
  border: 2px solid var(--base-green);
  box-shadow: 0px 20px 24px -4px rgb(16 24 40 / 8%),
    0px 8px 8px -4px rgb(16 24 40 / 3%);
  border-radius: 24px;
  padding: 24px;
  margin-top: 24px;
  position: relative;
  z-index: 1;
}

.btn-whisher {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  padding: 8px 10px;
  height: 42px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--base03);
  width: 200px;
  text-align: left;
}

.sparkles-bg {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-mask-image: linear-gradient(#000000, #ff000000);
  mask-image: linear-gradient(black, transparent);
  z-index: 1;
}

.AllGiftCard,
.giftcard {
  position: relative;
  overflow: hidden;
}

.AllGiftCard::before,
.giftcard::before {
  content: "";
  display: block;
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  -webkit-animation: shine 3s infinite;
  animation: shine 3s infinite;
  background: #ffffff;
  opacity: 0.5;
  filter: blur(150px);
  border-radius: 0;
  transform: rotate(30deg);
}

.loaderbar {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

@-webkit-keyframes shine {
  from {
    left: 0px;
  }

  to {
    left: 100%;
  }
}

@keyframes shine {
  from {
    left: 0px;
  }

  to {
    left: 100%;
  }
}

.Home-component .teamsup {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--base03);
  margin-bottom: 16px;
}

.Home-component .btnsubmit {
  margin-top: 36px;
}

.Home-component .btnsubmit .btn-pink {
  margin-bottom: 12px;
}

.Event-demo {
  margin-top: 24px;
  position: relative;
}

.Event-demo ul {
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
}

.Event-demo ul li.iconcircle {
  width: 40px;
  height: 40px;
  background: var(--icons-bg);
  border-radius: 20px;
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--icons-color);
  margin-right: 16px;
}

.home-wrapper .homebar ul li h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--base03);
}

.home-wrapper .homebar ul li p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--form-placeholder);
}

.Event-demo ul.mx-40 {
  margin: 40px 0;
}

.Event-demo::after {
  content: "";
  position: absolute;
  left: 19px;
  top: 10px;
  width: 2px;
  height: 85%;
  background: var(--icons-color);
  transform: translate(0px, 0px);
  z-index: 1;
}

.EventPartially h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--base03);
  margin-bottom: 24px;
}

.EventPartially h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
}

ul.EventPartially-list {
  display: block !important;
  margin-top: 12px;
}

ul.EventPartially-list li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}

ul.EventPartially-list li span {
  width: 20px;
  height: 20px;
  background: var(--base-note);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--base-note-text);
  margin-right: 8px;
  font-weight: 800;
}

.no-giftbar {
  text-align: center;
}

.no-giftbar img {
  width: 100px;
  margin-bottom: 10px;
}

.no-giftbar p {
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
}

.customiconbrand {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #e6f4d7;
  text-align: center;
  margin-right: 12px;
}

.customiconbrand svg {
  margin: 0 !important;
  color: #4f7a21;
  font-size: 16px;
}

/* .sparkles-bg {
    background-image: url('../../public/images/sparkles.png');
    background-position: top center;
    background-size: 180px;
    background-repeat: no-repeat;
} */
.avatar-img {
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.avatar-img img {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  object-fit: cover;
}

.Participantbar h4 {
  margin-bottom: 4px !important;
}

.user-name-email-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
  flex-wrap: wrap;
}

.message-description {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base01);
  margin-bottom: 24px;
}

.user-name-email-bar p,
.user-name-email-bar span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.user-name-email-bar span {
  margin: 0 5px;
  font-size: 20px;
}

.Participantbar .date {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--base01);
  margin-bottom: 16px;
}

.Participantbar .timeevent {
  margin-bottom: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--or-after);
}

.Participantbar .timeevent p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base01);
}

.Participantbar .timeevent h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--base-note-text);
}

.Participantbar h6.allusers-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base02);
}

.btncontribute .btn-pink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
}

.btncontribute .btn-pink span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base-white);
}

.btncontribute .btn-pink:hover span {
  color: var(--base-white);
}

.participantheight-auto {
  justify-content: flex-start !important;
}

.invitebar .invitebar-in .invite-icon svg {
  font-size: 20px;
  color: var(--base00);
}

.participantheight-auto .savebtn {
  margin-top: 40px;
}

.check-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: var(--base03);
  position: relative;
  text-align: center;
}

.invitebar .invitebar-in .invite-icon svg {
  color: var(--base00) !important;
  font-size: 20px !important;
}

.check-detail svg {
  color: var(--base00);
  font-size: 24px;
  margin-bottom: 5px;
  width: 26px;
  height: 26px;
}

.Customcheck .check-detail ~ input:checked {
  color: var(--base-white);
}

.Customcheckbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 0px 0 0;
  padding-top: 5px;
  overflow: hidden;
}

.Customcheckbar .rowbar {
  padding: 0 0;
  margin-right: 12px;
  margin-bottom: 12px;
  flex: 0 0 29.33%;
  max-width: 29.33%;
}

.Customcheck {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 88px;
  background: var(--base-white);
  border: 1px solid var(--base-border);
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 12px;
  padding: 12px 6px;
}

.Customcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 16px;
}

.Customcheck .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border-radius: 16px;
}

.Customcheck input.error ~ .checkmark {
  border: 1px solid var(--error-border) !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 4px #fee4e2 !important;
}

.Customcheck:hover input ~ .checkmark {
  background-color: transparent;
}

.Customcheck input:checked ~ .checkmark {
  background-color: var(--base00);
}

.Customcheck input:checked ~ .check-detail {
  color: var(--base-white);
  z-index: 5;
}

.Customcheck .check-detail svg {
  transition: 0.5s ease;
}

.Customcheck:hover .check-detail svg {
  transform: scale(1.2);
}

.Customcheck input:checked ~ .check-detail svg path {
  stroke: #ffffff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.Customcheck input:checked ~ .checkmark:after {
  display: none;
}

.Customcheck .checkmark:after {
  display: none;
}

.titleevent {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--base03);
  margin-bottom: 25px;
}

.formgroup {
  margin-bottom: 20px;
}

.rangebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.rangebar h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--icons-color);
  margin: 0;
}

.usericon {
  position: relative;
}

.usericon svg {
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  left: 15px;
}

.usericon .form-control {
  padding-left: 40px;
}

.datetimepicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datetimepicker button.btngray {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datetimepicker button.btngray svg {
  margin-right: 7px;
  font-size: 18px;
}

.modal-dialog {
  max-width: 440px;
  position: relative;
}

.datepicker-modal .modal-dialog .modal-body {
  padding: 10px;
}

.datepicker-modal .modal-dialog .modal-body .react-calendar {
  border: none;
}

.datepicker-modal .modal-dialog .modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datepicker-modal .modal-dialog .modal-footer button,
.datepicker-modal .modal-dialog .modal-footer a {
  margin: 0;
  width: 48%;
}

.terms-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}

.Organiser .EventCancelState {
  margin-top: 12px;
}

.Terms-text a {
  text-decoration: underline;
}

.groupgift-bluebar {
  margin-top: 24px;
  padding: 12px 18px;
  background: var(--base-note);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base-note-text);
}

.groupgift-bluebar svg {
  margin-right: 12px;
  color: var(--base-note-text);
  font-size: 26px;
}

.react-calendar__tile--active {
  background-color: var(--base00) !important;
  color: var(--base-white) !important;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  line-height: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--base03);
}

.react-calendar__tile {
  padding: 5px !important;
  height: 47px !important;
  border-radius: 50%;
}

.react-calendar__tile--now {
  background: #f2f4f7 !important;
  color: var(--base03) !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--base01) !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.inside-header {
  padding: 40px 0px;
}

.camera_svg {
  align-self: flex-end;
  position: relative;
  margin-left: -30px !important;
}

.event_lists_div {
  width: 100%;
  overflow: auto;
  padding-top: 24px;
}
.event_lists li {
  color: #ffffff;
  cursor: pointer;
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  white-space: nowrap;
}

.red-color {
  color: #c01048 !important;
  font-weight: 500;
  font-size: 14px;
}
.connection-card-div img {
  background: none;
}
.connection-card-status {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.connection-card-div {
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  padding: 16px;
  margin-bottom: 18px;
  align-items: center;
}
.connection-card-div .add-label {
  text-align: left;
}
.cursor-pointer {
  cursor: pointer;
}
.relation_p {
  cursor: pointer;
  display: flex;
  width: 375px;
  padding: 24px 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 18px;
  border-bottom: 1px solid #eaecf0;
}

.red-color.connections_text {
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 125% */
}

.event_lists li.selected {
  color: var(--Primary-700, #c01048);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  background-color: #ffffff;
  color: #e31b54;
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-50, #fff1f3);
}

.view_bottom {
  padding: 0px 16px;
}

.PxHeight30 {
  height: 30px !important;
}

.participant_list_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timebar h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--base02);
  margin-bottom: 16px;
}

.timebar {
  padding: 10px;
}

.timebar .react-timekeeper {
  width: 100% !important;
  box-shadow: none !important;
  background: var(--base00) !important;
  border-radius: 16px !important;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--base03);
  text-decoration: none;
}

button.react-calendar__navigation__arrow {
  font-size: 30px;
  color: var(--base01);
}

.timebar .react-timekeeper span[data-radium="true"] {
  color: var(--base-white) !important;
}

.timebar
  .react-timekeeper
  button.react-timekeeper-button-reset.react-timekeeper__meridiem-toggle {
  border-radius: 50%;
  position: relative;
  top: -10px;
  color: var(--base-white) !important;
  font-size: 16px !important;
}

.timebar
  .react-timekeeper
  .react-timekeeper__clock-wrapper
  button.react-timekeeper-button-reset.react-timekeeper__meridiem-toggle {
  color: rgb(153, 153, 153) !important;
}

.timebar
  .react-timekeeper
  .react-timekeeper__clock-wrapper
  span[data-radium="true"] {
  color: rgb(153, 153, 153) !important;
}

.timebar .react-timekeeper div {
  background: transparent !important;
  left: 0 !important;
  text-align: center;
}

.timebar .react-timekeeper .react-timekeeper__clock-wrapper {
  background: var(--base-white) !important;
}

.react-timekeeper__clock {
  background: gray !important;
}

.Createevent-modal .modal-header {
  border: none;
  padding: 20px 20px 20px 20px;
}

.Createevent-modal .modal-body {
  padding: 20px 20px 20px 20px;
}

.Createevent-modal .CreateEvent-form svg {
  font-size: 53px;
  color: var(--base00);
  margin-bottom: 20px;
}

.Createevent-modal .CreateEvent-form .formgroup {
  margin-bottom: 24px;
}

.Createevent-modal .modal-content {
  border-radius: 24px 24px 0 0;
}

.Event-addbar {
  padding: 12px 16px;
  border: 1px solid var(--base-border);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Event-addbar .eventleft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Event-addbar .eventleft svg {
  font-size: 24px;
  color: var(--base00);
  margin-right: 19px;
}

.Event-addbar .eventleft .detail h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--base03);
  margin: 0;
}

.Event-addbar .eventleft .detail h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base01);
  margin: 0;
}

.Event-addbar svg {
  color: var(--base00);
  font-size: 24px;
}

.giftnote .note {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base01);
  margin-top: 5px;
}

.giftnote .note {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base01);
  margin-top: 5px;
}

.groupgift-bluebar.noflex {
  display: block;
}

.groupgift-bluebar.noflex h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base-note-text);
  margin: 0 0 4px 0;
}

.uploadmedia {
  margin-top: 24px;
  padding: 18px 50px;
  background: var(--base-white);
  border: 1px dashed var(--base-border);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadmedia .image-up {
  margin-right: 12px;
  position: relative;
  width: 96px;
  height: 96px;
  background: var(--base-white);
  border: 1px solid var(--base-border);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 72px;
  flex: 0 0 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.uploadmedia .image-up input.uploadinput {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.uploadmedia .image-up span {
  width: 72px;
  height: 72px;
  background: #f9fafb;
  border-radius: 200px;
  text-align: center;
  display: flex;
  align-items: center;
}

.uploadmedia .upload-text h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base00);
  margin-bottom: 4px;
}

.uploadmedia .upload-text p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base01);
}

.imageupload-editbar {
  padding: 16px !important;
  justify-content: space-between;
  margin-bottom: 24px;
}

.eventbar .btn-pink {
  margin-top: 50px;
}

.imageupload-editbar .uploadimg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.imageupload-editbar .uploadimg img {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.imageupload-editbar .uploadimg .detailbar h5 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--base02);
}

.imageupload-editbar .uploadimg .detailbar p {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base01);
}

.uploadmedia img,
.edit-profile-img-wrapper {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}

.connections-type .connection-search-div {
  padding: 0px;
}

.connections-type .event_lists li.selected {
  color: #ffffff;
  background: #e44654;
}
.connections-type .event_lists li {
  color: #e44654;
}
.connections-type .event_lists {
  display: flex;
  padding-bottom: 15px;
}

.connections-type.event_lists_div {
  padding: 15px 0px;
}

.edit-profile-img-icon img {
  height: 15px;
  width: 15px;
  border-radius: unset;
}

.connection-card-name {
  font-size: 16px;
}

/* .connection-card-status p {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: left;
  color: var(--base01);
} */

.edit-profile-img-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #00000078;
  bottom: 0;
  padding: 5px;
}

.imageupload-editbar a.editbar {
  font-size: 24px;
  color: var(--base00);
  cursor: pointer;
}

.successbar {
  text-align: center;
  padding: 70px 0 30px 0;
}

.successbar img {
  margin-bottom: 45px;
}

.successbar h6 {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--base03);
}

.Success-bg .cross-icon {
  font-size: 30px;
  text-align: center;
  color: #e44654;
  width: 50px;
  height: 50px;
  margin: 0 auto 20px auto;
  border: 2px solid #e44654;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 0 !important;
}

.profilehead ul li h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--form-input);
  margin: 0;
}

.profilehead ul li a {
  color: var(--base01);
  font-size: 20px;
}

.Helpbar {
  margin-top: 30px;
}

.formgroup-relative {
  position: relative;
}

.withoutsvg .form-control {
  padding-left: 20px;
}

.Helpbar p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
  margin-bottom: 16px;
}

.Helpbar ul.Number-email-detail li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.Helpbar ul.Number-email-detail li p {
  flex: 0 0 68%;
  margin: 0;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px;
  color: var(--base03);
}

.Helpbar p {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--base03) !important;
  margin-bottom: 16px;
}

.z-index-1 {
  z-index: 1;
}

.Number-email-detail {
  margin-bottom: 30px;
}

.SocialMedia h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
  margin-bottom: 16px;
}

ul.Social-bar li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

ul.Social-bar li .Leftbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

ul.Social-bar li .btngray {
  flex: 0 0 123px;
  padding: 8px 10px !important;
  height: auto;
}

ul.Social-bar li .Leftbar img {
  margin-right: 14px;
  transition: 0.3s ease;
}

ul.Social-bar li:hover .Leftbar img {
  transform: translate(0px, -3px);
}

.newsletter-form form {
  position: relative;
}

.newsletter-form form .formbtn {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translate(0px, -50%);
}

.newsletter-form form .formbtn button.btn-pink {
  width: 100% !important;
  height: 100% !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%) !important;
  border-radius: 8px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  padding: 8px 12px;
  min-width: 108px;
}

.newsletter-form form .form-control {
  padding: 12px 124px 12px 14px;
}

ul.Social-bar li .Leftbar p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--base03);
}

ul.Social-bar li:last-child {
  margin-bottom: 0;
}

.SocialMedia {
  margin-bottom: 30px;
}

.AboutUs h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
  margin-bottom: 4px;
}

.border-dooted-top {
  padding-top: 30px !important;
  border-top: 4px dotted #e94041;
  margin-right: 30px;
  padding-right: 0;
}

.blogs-box {
  margin-bottom: 30px;
}

.blog-content {
  margin-bottom: 30px !important;
  padding-top: 80px !important;
  max-width: 100% !important;
}

.blogs-box h4 {
  font-size: 28px;
  color: #e94041;
  margin-bottom: 20px;
}

.blog-content .banner-shape {
  padding-top: 10px !important;
}

.blogs-box p {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 26px;
}

.AboutUs p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base01);
}

.profile-tophead .uploadmedia {
  margin: 0 auto 20px auto;
  padding: 0;
  border: none;
  justify-content: flex-start;
}

.profile-tophead .userinfo-bar {
  margin-bottom: 16px;
}

.userinfo-bar h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--base03);
  margin-bottom: 9px;
}

.userinfo-bar p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base01);
}

.GroupGiftBox {
  margin: 40px 0;
}

.GroupGiftBox h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 16px;
  color: var(--form-input);
}

.GroupGiftBox p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--base01);
}

.social-img {
  height: 35px !important;
  width: auto !important;
  cursor: pointer;
}

.greengift-cards {
  background: var(--lightgreen-bg);
  border: 1px solid var(--green-border);
  border-radius: 24px;
  padding: 20px;
}

.greengift-cards .porse-icon {
  width: 48px;
  height: 48px;
  background: var(--total-greenbg);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: var(--base-white);
  margin-bottom: 16px;
}

.greengift-cards h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--form-input);
  margin-bottom: 4px;
}

.greengift-cards p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--base01);
}

.GroupGiftBox .greengift-cards {
  margin-top: 24px;
}

.FaqPage {
  background: var(--teritory-text);
  border-radius: 32px;
  padding: 40px 24px;
}

.FaqTitle {
  text-align: center;
  margin-bottom: 40px;
}

.FaqTitle h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--base-white);
  margin-bottom: 16px;
}

.FaqTitle p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--teritory-active);
}

.FaqAccordion .accordion {
  background: transparent !important;
  border: none !important;
}

.FaqAccordion .accordion .accordion-item {
  background: transparent !important;
  border: none !important;
}

.FaqAccordion .accordion .accordion-item .accordion-button {
  background: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--base-white);
  padding: 0 !important;
}

.FaqAccordion .accordion .accordion-item .accordion-body {
  padding: 8px 0 0 0;
}

.FaqAccordion .accordion .accordion-item .accordion-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--teritory-active);
}

.FaqAccordion
  .accordion
  .accordion-item
  .accordion-button:not(.collapsed)::after {
  background-image: url("../../public/images/MinusIcon.png") !important;
}

.FaqAccordion .accordion .accordion-item .accordion-button::after {
  background-image: url("../../public/images/plusIcon.png") !important;
  width: 20px !important;
  height: 20px !important;
  background: transparent;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.FaqAccordion .accordion .accordion-item {
  padding-bottom: 28px;
  border-bottom: 1px solid var(--base00) !important;
  margin-bottom: 28px;
}

.FaqAccordion .accordion .accordion-item:last-child {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.FaqAccordion .accordion .accordion-item:last-child {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.FaqQuestionBox {
  background: var(--base00);
  border-radius: 16px;
  padding: 32px 20px;
  text-align: center;
}

.FaqPage .FaqQuestionBox {
  margin-top: 48px;
}

.FaqQuestionBox h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--base-white);
  margin-bottom: 8px;
}

.FaqQuestionBox p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--teritory-active);
  margin-bottom: 24px;
}

.FaqPage .FaqQuestionBox a {
  padding: 12px 20px;
  background: var(--btn-pinkhover);
  border: 1px solid var(--btn-pinkhover);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--base-white);
  display: inline-block;
}

.StatesListing {
  padding: 40px 24px;
}

.StatesListing .EventStates {
  padding: 40px 24px;
  background: var(--states-bg);
  border-radius: 24px;
}

.StatesListing .EventStates .statesbar {
  text-align: center;
}

.StatesListing .EventStates .statesbar h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--states-head);
  margin-bottom: 2px;
}

.StatesListing .EventStates .statesbar h6 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--states-subhead);
}

.StatesListing .EventStates .statesbar.centerbar {
  margin: 32px 0;
}

.StatesListing .EventStates .statesbar.centerbar {
  margin: 32px 0;
}

.Testimonials {
  padding: 60px 24px;
  background: var(--testi-bg);
  border-radius: 32px 32px 0 0;
}

.testimonials-content-text .inputbar {
  display: flex;
  align-items: center;
  position: relative;
}

.testimonials-content-text .inputbar button.btnsend {
  background: transparent;
  border: none;
  color: var(--base01);
  padding: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
  cursor: pointer;
}

.testimonials-content-text .inputbar input.form-control {
  padding-right: 30px;
}

.Testimonials h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--form-input);
  margin-bottom: 16px;
}

.Testimonials p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--base01);
}

.Testimonial-block {
  margin-top: 45px;
}

.Testimonial-block .userdetail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.Testimonial-block .userdetail img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
}

[data-page^="/testimoniallist/"] .videoRecording {
  padding: 0;
  height: calc(100vh - 70px);
  margin: 0 -15px;
}

[data-page^="/testimoniallist/"]
  .body-wrapper
  .disconnected-view__SVGWrapper-sc-1hdjm8z-0 {
  display: none;
}

.review {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: var(--form-input);
  margin: 15px 0 32px 0;
}

.videoRecButton {
  padding-top: 10px;
  margin-top: 2px;
}

.Testimonial-block .userdetail h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--form-input);
  margin: 0;
}

.Testimonial-block .userdetail p {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--base01) !important;
}

.Testimonials .carousel-indicators {
  display: none;
}

.Testimonials a.carousel-control-prev,
.Testimonials a.carousel-control-next {
  width: 48px !important;
  height: 48px !important;
  opacity: 1 !important;
  position: absolute;
  right: 10px !important;
  left: inherit !important;
  bottom: 0px !important;
  top: inherit !important;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--or-after);
  backdrop-filter: blur(4px);
}

.Testimonials a.carousel-control-prev {
  right: 70px !important;
}

.Testimonials a.carousel-control-prev span,
.Testimonials a.carousel-control-next span {
  filter: brightness(0);
  background-size: 20px;
}

.Footer {
  background: var(--base03);
  padding: 48px;
  position: relative;
}

.Footer .foot-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
}

.Footer .foot-links {
  display: flex;
  flex-wrap: wrap;
}

.Footer .foot-links li {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 10px;
}

.Footer .foot-links li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--or-after);
}

.Footer .social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 48px;
}

.Footer .social-links li a {
  color: var(--form-placeholder);
  font-size: 28px;
}

.Footer .foot-links li a.active {
  color: var(--base00);
}

.Footer .social-links li {
  margin: 0 12px;
}

.Footer p.note {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--form-placeholder);
  margin-top: 30px;
}

.AddTestimonial {
  height: calc(100vh - 140px);
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-bottom: 130px;
  overflow: scroll;
}

.review-empty {
  background-image: url("../../public/images/AlluraParty.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.add-review-bar {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  padding: 16px 16px 16px 16px;
  background: var(--base-white);
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: var(--base-border);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, 0px);
}

.add-review-bar .inputbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 320px;
  margin-right: 20px;
}

.add-review-bar .inputbar .form-control {
  padding: 12px 40px 12px 14px;
}

.add-review-bar .inputbar .btnsend {
  position: absolute;
  right: 15px;
  font-size: 20px;
  color: var(--base01);
  border: none;
  background: transparent;
  cursor: pointer;
}

.add-review-bar .recorded-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* .add-review-bar .recorded-btn li {
  margin-left: 20px;
} */

.add-review-bar .recorded-btn li:first-child {
  margin-left: 0;
}

.add-review-bar .recorded-btn li .btnrecord {
  border: none;
  background: transparent;
  color: var(--base03);
  font-size: 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.add-review-bar .recorded-btn li .btnrecord .uploadinput {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.SearchInput {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.SearchInput svg {
  font-size: 24px;
  position: absolute;
  left: 15px;
  color: var(--base01);
}

.SearchInput .form-control {
  padding: 12px 14px 12px 45px;
}

.TestimonialList {
  position: relative;
  padding-bottom: 200px;
  height: 100vh;
}

.testimonial-tabs {
  margin-top: 24px;
  margin-bottom: 70px;
}

.testimonial-tabs .nav-tabs {
  border: none;
  margin-bottom: 34px;
}

.testimonial-tabs .nav-tabs li button {
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--base01);
  margin: 0;
  padding: 8px 8px;
  border-radius: 6px;
  transition: 0.3s ease;
}

.testimonial-tabs .nav-tabs li button:hover,
.testimonial-tabs .nav-tabs li button.active {
  background: var(--teritory-bg);
  color: var(--teritory-text);
}

.testimonial-tabs .nav-tabs li.nav-item {
  margin-right: 5px;
}

.testimonial-tabs .nav-tabs li.nav-item {
  margin-right: 5px;
}

.testimonial-tabs .nav-tabs li button.active {
  background: var(--teritory-bg);
  color: var(--teritory-text);
}

.Testi-content {
  background: var(--base-white);
  border: 1px solid #f2f4f7;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 34px;
  width: 100%;
  padding: 16px;
}

.underprocess {
  padding: 15px 20px;
  text-align: center;
  background: #e446541f;
  border-radius: 8px;
  font-size: 16px !important;
  color: #e44654 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.Testi-content .mediauploaded {
  width: 320px;
  height: 320px;
  overflow: hidden;
  border-radius: 24px;
  margin: 0 auto;
}

.Testi-content .mediauploaded video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.Testi-content .mediauploaded iframe,
.Testi-content .mediauploaded img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #eee;
}

.Testi-content .testimonial-content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 16px 0;
}

.Testi-content .testimonial-content-head .headleft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Testi-content .testimonial-content-head .headleft h6 {
  margin: 0 0 0 8px;
}

.AddTestimonialBox button.btn-pink {
  margin-bottom: 12px;
}

.Testi-content .testimonial-content-head .headleft img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.Testi-content .testimonial-content-head .headryt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--form-placeholder);
}

.Testi-content .testimonial-content-head .headryt p.date {
  margin-right: 5px;
}

.Testi-content .testimonial-content-head .headryt p.time {
  margin-left: 5px;
}

.Testi-content .testimonial-content-head .headryt p {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  color: var(--form-placeholder);
}

.Testi-content .testimonial-content-text {
  margin-bottom: 25px;
}

.Testi-content .testimonial-content-text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base01);
}

.testimonial-tabs-content .Testi-content {
  margin-bottom: 30px;
}

.modify-list .dropdown {
  text-align: right;
}

.modify-list .dropdown button.btntrans {
  background: transparent !important;
  border: none !important;
  color: var(--base03) !important;
  font-size: 20px;
  padding: 0 !important;
}

.modify-list .dropdown button.btntrans::after {
  display: none;
}

.modify-list .dropdown .dropdown-menu {
  padding: 0 !important;
}

.modify-list .dropdown .dropdown-menu .dropdown-item {
  padding: 10px;
  border-bottom: 1px solid var(--base-border);
  font-size: 16px;
}

.modify-list .dropdown .dropdown-menu .dropdown-item svg {
  margin-right: 10px;
  font-size: 16px;
  color: var(--base03);
}

.Testi-content audio {
  width: 100%;
}

.ContributionPage {
  height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ContributionPage .headcontri h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--base03);
  margin-bottom: 16px;
}

.Contributionbox .accordion .accordion-item {
  background: var(--base-white);
  border: 2px solid var(--form-placeholder);
  border-radius: 12px;
  padding: 0;
}

.Contributionbox .accordion .accordion-item.active .accordion-header button {
  background: var(--states-bg);
  border-radius: 12px;
}

.Contributionbox .accordion .accordion-item.active {
  border-color: var(--total-greenbg);
}

.Contributionbox .accordion .accordion-item .accordion-header,
.Contributionbox .accordion .accordion-item .accordion-header button {
  border-radius: 12px 12px;
}

.boxwithnumeric-block .boxwithnumeric .savebtn button.btn-pink {
  margin-bottom: 10px;
}

.Contributionbox
  .accordion
  .accordion-item
  .accordion-header
  button:not(.collapsed)
  .typecheckbox
  .check {
  display: none;
}

.Contributionbox
  .accordion
  .accordion-item.active
  .accordion-collapse.collapse {
  display: block;
}

.Contributionbox
  .accordion
  .accordion-item.active
  button.accordion-button
  .typecheckbox
  .check {
  display: none;
}

.Contributionbox
  .accordion
  .accordion-item.active
  button.accordion-button
  .typecheckbox
  img {
  display: block !important;
}

.Contributionbox
  .accordion
  .accordion-item
  .accordion-header
  button:not(.collapsed)
  .typecheckbox
  img {
  display: block !important;
}

.Contributionbox .accordion .accordion-item .accordion-header button {
  padding: 12px 16px;
  background: var(--base-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.Contributionbox .accordion .accordion-item .accordion-header button::after {
  display: none;
}

.Contributionbox
  .accordion
  .accordion-item
  .accordion-header
  button
  .headeraccor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Contributionbox
  .accordion
  .accordion-item
  .accordion-header
  button
  .headeraccor
  .typecheckbox {
  flex: 0 0 24px;
  max-width: 24px;
  margin-right: 16px;
}

.Contributionbox
  .accordion
  .accordion-item
  .accordion-header
  button
  .headeraccor
  .typecheckbox
  img {
  display: none;
}

.Contributionbox
  .accordion
  .accordion-item
  .accordion-header
  button
  .headeraccor
  .typecheckbox
  .check {
  width: 24px;
  height: 24px;
  background: var(--base-white);
  border: 1px solid var(--base-border);
  border-radius: 26px;
}

.Contributionbox
  .accordion
  .accordion-item
  .accordion-header
  button
  .headeraccor
  .titlebar
  h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--base03);
  margin: 0;
}

.revokebar p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.revokebar .revoke-contain {
  margin-top: 24px;
  margin-bottom: 24px;
}

.revokebar .revoke-contain .head {
  padding: 8px 16px;
  background: #eaecf0;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.revokebar .revoke-contain .head p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}

.revokebar .revoke-contain .revokeweraper {
  padding: 12px 15px;
  border: 1px solid #eaecf0;
  border-radius: 0px 0px 8px 8px;
}

.revokebar .revoke-contain .revokeweraper ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.revokebar .revoke-contain .revokeweraper ul li:nth-child(2) p {
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}

.revokebar .revoke-contain .revokeweraper ul li h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #344054;
  margin: 0;
}

.revokebar .revoke-contain .revokeweraper ul li p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #667085;
}

.revokebar .btnbar p {
  margin: 12px 0;
}

.Contributionbox
  .accordion
  .accordion-item
  .accordion-header
  button
  .headeraccor
  .titlebar
  p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base01);
}

.Contributionbox .accordion .accordion-item .accordion-header button .icon {
  flex: 0 0 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: revert;
  margin-left: 12px;
  background: var(--icons-bg);
  border-radius: 20px;
  width: 40px;
  height: 40px;
}

.Contributionbox .accordion .accordion-item .accordion-body {
  padding: 16px 16px 24px 16px;
  border-top: 2px solid var(--total-greenbg);
}

.Contributionbox .accordion .accordion-item .accordion-body p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
  margin-bottom: 10px;
}

.amount-type {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.amount-type svg {
  position: absolute;
  font-size: 24px;
  left: 15px;
  color: var(--base-border);
}

.amount-type input.form-control {
  padding: 12px 14px 12px 40px;
}

.note-error {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.note-error svg {
  font-weight: 400;
  font-size: 40px;
  line-height: 20px;
  color: var(--base-note-text);
  margin-right: 10px;
}

.ContributionPage .contri-bottom .btn-pink svg {
  margin-right: 8px;
  font-size: 18px;
}

.note-error p {
  margin: 0 !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--base-note-text) !important;
}

.ContributionPage .accordion .accordion-item {
  margin-bottom: 24px;
}

.Contributionbox .accordion .accordion-item.active .accordion-header button {
  background: var(--states-bg);
}

.react-switch {
  background: #f2f4f7 !important;
  border-radius: 12px !important;
  width: 44px !important;
  height: 24px !important;
}

.react-switch .react-switch-bg {
  margin: 0 !important;
}

.react-switch .react-switch-handle {
  width: 20px !important;
  height: 20px !important;
  background: var(--base-white) !important;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  top: 2px !important;
  left: 2px;
}

.country-price ul.perviewlist li img {
  width: 41px;
  height: 41px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06), 0px 0px 0px 4px #f2f4f7;
  border-radius: 50%;
}

.anyamount {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.anyamount p {
  margin: 0 0 0 12px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--base03) !important;
}

.participant-list-ul li:last-child {
  border: none;
}

.ContributionPage .contri-bottom {
  padding-bottom: 20px;
}

.ContributionPage .contri-bottom p {
  margin-top: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base02);
}

.FixedAmount img {
  margin-bottom: 29px;
}

.FixedAmount h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--base03);
  margin: 0;
}

.FixedAmount p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base01);
}

.FixedAmount .fixform {
  margin: 24px 0;
}

.amount-type span.perperson {
  position: absolute;
  right: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
}

.FixedAmount .fixform .amount-type .form-control {
  padding: 4px 80px 4px 40px;
  font-size: 36px;
  line-height: 1;
}

.FixedAmount .note-error {
  margin-bottom: 20px;
}

.FixedAmount .anyamount {
  margin-top: 24px;
}

.OnboardSlider .carousel .carousel-caption {
  position: relative;
  right: inherit;
  left: inherit;
  transform: inherit;
  padding: 0;
}

.OnboardSlider .carousel a.carousel-control-next,
.OnboardSlider .carousel a.carousel-control-prev {
  display: none;
}

.carousel-indicators {
  margin: 0 0;
}

.carousel-indicators button {
  width: 12px !important;
  height: 8px !important;
  background: var(--or-after) !important;
  border-radius: 32px;
  flex: inherit !important;
  border: none !important;
  opacity: 1 !important;
  transition: 1s ease !important;
}

.carousel-indicators button.active {
  width: 300px !important;
}

.Organiser {
  position: relative;
  overflow: hidden;
}

.Organiser h6.timetitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base03);
}

.EventPartially-list .checkbar span {
  background: var(--icons-bg);
}

.Organiser h4 {
  color: var(--base03);
  margin-bottom: 24px;
  position: relative;
  z-index: 2;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
}

.organiser-mainboxes {
  margin-top: 60px;
  position: relative;
}

.boxwithnumeric-block .boxwithnumeric {
  background: var(--base-white);
  border: 1px solid var(--or-after);
  border-radius: 0 0 16px 16px;
  padding: 24px 16px;
}

.boxwithnumeric-block {
  margin-bottom: 60px;
}

.boxwithnumeric-block .numericbar {
  width: 100%;
  height: 66px;
  background: var(--base-note);
  border-radius: 16px 16px 0px 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: -28px 0px;
  padding: 8px 8px 8px 8px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #0086c9;
}

.boxwithnumeric-block .boxwithnumeric .formgroup {
  margin-bottom: 0;
}

.boxwithnumeric-block .boxwithnumeric .savebtn {
  margin-top: 12px;
}

.boxwithnumeric-block .boxwithnumeric .savebtn .btn-pink svg {
  margin-right: 10px;
}

.MoneyCollectBox h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--base03);
}

.datetimebox h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--base03);
}

.settingbtn {
  background: transparent !important;
  outline: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.settingbtn svg {
  margin: 0 !important;
  transition: 0.2s ease;
}

.settingbtn:hover svg {
  transform: rotate(180deg);
}

.MoneyCollectBox h4 svg {
  margin-right: 12px;
}

.MoneyCollectBox .moneyaddedbar {
  text-align: center;
  margin-bottom: 20px;
}

.AddParticipant .participant-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.AddParticipant .participant-head .headleft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.AddParticipant .participant-head .headleft svg {
  margin-right: 12px;
}

.AddParticipant .participant-head .headleft .participant-detail h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--base03);
  margin-bottom: 0;
}

.AddParticipant .participant-head .headleft .participant-detail p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base01);
}

.btnpink-astext {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--teritory-text);
  cursor: pointer;
}

.AddParticipant {
  position: relative;
}

.AddParticipant .participant-list {
  margin-top: 25px;
}

.participant-list-ul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--or-after);
}

.participant-list-ul li,
.participant-list-ul .li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.participant-list-ul li h4.name {
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
  margin: 0;
  font-weight: 400;
}

.org {
  color: #0086c9;
}

.participant-list-ul li label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.Event-demo ul li h5 {
  margin-bottom: 0;
}

.AddParticipant .togglebar {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.AddParticipant .togglebar .react-switch {
  margin-right: 12px;
}

.AddParticipant .togglebar p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
}

.AddParticipant .participant-avatar {
  text-align: center;
}

.addgiftcard .togglebar {
  padding-top: 10px;
  border-top: 1px solid var(--or-after);
}

[data-page="/recepient"] .Headerbar {
  display: none;
}

.Recepient {
  position: relative;
  padding: 60px 0px 20px 0px;
}

.Recepient .avatar-img {
  text-align: center;
}

.Recepient .avatar-img img {
  width: 180px;
  height: 180px;
  object-fit: cover;
}

.Recepient .sparkles-bg {
  height: 240px;
}

.Recepient .receipient-message .font-send {
  font-family: "Send Flowers", cursive;
  font-weight: 400;
  font-size: 46px;
  line-height: 38px;
  text-align: center;
  color: var(--base03);
  margin-bottom: 20px;
}

.Recepient .receipient-message h3 {
  margin: 0;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: var(--base03);
  text-align: center;
}

/* @import url('https://fonts.googleapis.com/css2?family=Send+Flowers&display=swap');  */
@import url("https://fonts.googleapis.com/css2?family=Kite+One&family=Send+Flowers&display=swap");

.AddKudos {
  background: #f5fbee;
  border: 1px solid #86cb3c;
  box-shadow: 0px 12px 16px -4px rgba(134, 203, 60, 0.16),
    0px 4px 6px -2px rgba(134, 203, 60, 0.12);
  border-radius: 16px;
  padding: 16px;
  text-align: center;
}

.AddKudos img {
  margin-bottom: 12px;
}

.AddKudos h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--base03);
  margin: 0;
}

.AddKudos p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base03);
}

.AddKudos .addkudos-form {
  margin-top: 25px;
}

.AddKudos .addkudos-form form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.AddKudos .addkudos-form form .btn-pink {
  padding: 8px 8px !important;
  width: 105px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 20px;
  margin-left: 12px;
  height: 42px;
}

.AddKudos .addkudos-form form .form-control {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 14px;
  height: 42px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
}

.AddKudos .addkudos-form form .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
}

.Recepient .AddKudos {
  margin-bottom: 30px;
  margin-top: 30px;
}

.OpenGiftCard h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--base03);
}

.OpenGiftCard .giftcard-in {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 24px 24px 16px 16px;
  background: #ffffff;
  text-align: center;
}

.Createevent-modal.addanother-modal {
  background: rgb(0 0 0 / 65%);
}

.Createevent-modal.addanother-modal .modal-body {
  text-align: center;
}

.Createevent-modal.addanother-modal .modal-body img {
  margin-bottom: 46px;
}

.Createevent-modal.addanother-modal .modal-body h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  text-align: center;

  /* Gray/700 */
  color: var(--base03);
  margin-bottom: 0px;
}

.Createevent-modal.addanother-modal .modal-body h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--base03);
}

.invitecontent {
  margin-bottom: 16px;
}

.invitecontent p {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--base00);
}

.invitecontent p b {
  font-weight: 700;
}

.Createevent-modal.addanother-modal .modal-body .btnbar {
  margin-top: 32px;
}

.Createevent-modal.addanother-modal .modal-body .btnbar .btn-pink {
  margin-bottom: 20px;
}

.OpenGiftCard .giftcard-in .spacer {
  margin-top: 12px;
  padding: 4px 16px 16px 16px;
}

.testimonial-tabs .Testi-content {
  margin-bottom: 20px;
}

.Recepient .testimonialbar {
  margin-top: 16px;
}

.Recepient .testimonialbar h4.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--base03);
  margin: 0;
}

.Recepient .testimonialbar p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--base03);
}

.Recepient .testimonialbar .testimonial-tabs-content {
  margin-top: 24px;
}

.OpenGiftCard.allcards {
  height: auto;
}

.allcards {
  height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.allcards .cards-head {
  margin-bottom: 20px;
}

.allcards .cards-foot {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 20px;
}

.allcards .giftcard-in {
  margin-bottom: 20px;
  padding: 10px;
}

.allcards .cards-foot p {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #667085;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.allcards .cards-foot p .cardpopup {
  margin-left: 12px;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

.allcards .cards-head h2.accordion-header {
  border: none;
}

.allcards .cards-head h2.accordion-header .accordion-button {
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent !important;
}

.allcards .cards-head .accordion-item {
  padding: 0;
  border: none;
}

.allcards .cards-head h2.accordion-header .accordion-button ::after {
  display: none;
}

.allcards .cards-head .accordion-button::after {
  display: none;
}

.allcards .cards-head .accordion-item .accordion-collapse {
  max-width: 90%;
  margin: 0 auto;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #d0d5dd;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 0px 0px 16px 16px;
  padding: 24px;
}

.allcards .cards-head .accordion-item .accordion-collapse .accordion-body {
  padding: 0;
}

.allcards
  .cards-head
  .accordion-item
  .accordion-collapse
  .carddetail
  .detailin {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.allcards
  .cards-head
  .accordion-item
  .accordion-collapse
  .carddetail
  .detailin
  .inleft
  label {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #98a2b3;
}

.allcards
  .cards-head
  .accordion-item
  .accordion-collapse
  .carddetail
  .detailin
  .inleft
  h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: var(--base03);
}

.allcards .cards-head .accordion-item .accordion-collapse .carddetail .notebox {
  text-align: center;
}

.allcards
  .cards-head
  .accordion-item
  .accordion-collapse
  .carddetail
  .notebox
  p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #98a2b3;
}

.allcards
  .cards-head
  .accordion-item
  .accordion-collapse
  .carddetail
  .detailin
  .copybtn
  .btngray
  svg {
  margin-right: 10.5px;
}

.giftcard-modal .modal-content {
  border-radius: 24px 24px 0 0;
  border: none;
}

.giftcard-modal .modal-content .modal-header {
  padding: 28px;
  border: none;
}

.giftcard-modal .modal-content .modal-header .modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--base03);
}

.giftcard-modal .modal-content .modal-body {
  padding-top: 0;
}

.giftcard-modal .modal-content .modal-body ul.EventPartially-list {
  margin: 0;
}

.giftcardbasic {
  display: flex;
  justify-content: space-between;
}

.detailin.d-flex-justify {
  display: flex;
  justify-content: space-between;
}

.copybtn {
  align-self: center;
}

.giftcard-modal .modal-content .modal-body ul.EventPartially-list li span {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  margin-right: 12px;
}

.allgiftscard_bar.allcards .cards-head h2.accordion-header {
  padding: 10px 0;
}

.allgiftscard_bar.allcards .cards-head .accordion-collapse {
  border: none;
  max-width: 100%;
}

.allgiftscard_bar.allcards .cards-head .accordion-collapse li,
.allgiftscard_bar.allcards .cards-head .accordion-collapse p {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}

.allgiftscard_bar .carddetail .inleft label {
  font-size: 14px;
  line-height: 20px;
  color: #98a2b3;
}

.allgiftscard_bar .carddetail .copybtn {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  display: flex;
  align-content: center;
  justify-content: center;
  color: #212121;
  box-shadow: 0px 0px 5px #1018280d;
}

.allgiftscard_bar .carddetail .inleft h4 {
  font-size: 20px;
  line-height: 30px;
  color: #344054;
  margin: 0;
}

.allgiftscard_bar .carddetail .detailin {
  margin: 0 0 0 0 !important;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #eaecf0;
}

.allgiftscard_bar .carddetail {
  padding: 30px 0;
}

.allgiftscard_bar .carddetail .detailin:last-child {
  border: none;
}

.allgiftscard_bar .AllGiftCard .giftcardbasic {
  padding: 16px;
  background: rgb(232 64 78 / 10%);
  border-radius: 5px;
  display: grid;
  text-align: left;
  gap: 10px;
}

.allgiftscard_bar .AllGiftCard .giftcardbasic span {
  color: #344054;
}

.wellwisherlist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
}

.wellwisherlist .btn-fix {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0px);
  z-index: 100;
  background: #ffffff;
  padding: 21px 24px;
  max-width: 440px;
}

.wellwisherlist ul {
  padding-bottom: 130px;
}

.wellwisherlist ul li {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wellwisherlist ul li .btngray {
  width: auto;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 20px;
  color: var(--base03);
  padding: 8px 8px !important;
  height: auto;
  flex: 0 0 100px;
}

.wellwisherlist ul li h4 {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--base03);
}

.Organiser.screen2 .timeevent {
  margin: 0;
  padding: 0;
  border: none;
}

.Organiser.screen2 .editevent {
  margin: 24px 0;
}

.text-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base00);
  cursor: pointer;
}

.Organiser.screen2 .boxwithnumeric-block {
  margin-bottom: 24px !important;
}

.Organiser.screen2 .preview-wish .boxwithnumeric p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
  margin-bottom: 12px;
}

.Organiser.screen2 .preview-wish .boxwithnumeric p span {
  color: #e32854;
}

.Organiser.screen2 .preview-wish .boxwithnumeric .btn-pink {
  width: auto !important;
  display: inline-flex;
  padding: 8px 14px;
}

.price-collected {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eaecf0;
}

.price-collected h4 {
  margin: 0;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
}

.price-collected h4 .graybar {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.price-collected h4 span {
  margin-left: 6px;
}

.MoneyCollectBox .country-price {
  margin-bottom: 16px;
}

.MoneyCollectBox .country-price h6.allusers-text {
  margin: 8px 0 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.boxwithnumeric-block .boxwithnumeric .savebtn a {
  margin-bottom: 16px;
}

.boxwithnumeric-block .boxwithnumeric .MoneyCollectBox .per-person {
  padding-top: 16px;
  border-top: 1px solid #eaecf0;
}

.boxwithnumeric-block .boxwithnumeric .MoneyCollectBox .per-person h6 {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.green {
  color: #4f7a21;
}

.green svg {
  font-size: 18px;
  margin-right: 3px;
}

.peoplejoined {
  margin: 24px 0;
  position: relative;
  text-align: center;
}

.peoplejoined h4 {
  position: relative;
  z-index: 2;
  margin: 0;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center;
  color: var(--base03);
  display: inline-block;
  background: var(--base-white);
  padding: 0 10px;
}

.peoplejoined::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  background: #d9d9d9;
  height: 1px;
  transform: translate(0px, -50%);
  z-index: 1;
}

.AddParticipant .participant-head .headryt .editbtn {
  font-size: 16px;
  color: var(--base03);
  cursor: pointer;
}

.boxwithnumeric-block .boxwithnumeric .giftcard {
  text-align: center;
}

.AddParticipant .willsent {
  padding: 16px 0 0 0;
  text-align: center;
}

.AddParticipant .willsent h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--base01);
  margin-bottom: 0;
}

.AddParticipant .willsent p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--base00);
}

.AddTestimonialBox .testimonails-listing {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--or-after);
}

.AddTestimonialBox .testimonails-listing li {
  margin-right: 15px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--base03);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.invitebar .invitebar-in .invite-icon svg {
  font-size: 20px;
  color: var(--);
}

.AddTestimonialBox .testimonails-listing li span {
  margin-right: 5px;
}

.home-wrapper .homebar ul li p.email-verify {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.home-wrapper .homebar ul li p.email-verify .verify-btn {
  margin: 0 0 0 10px;
}

.usericon .row {
  justify-content: space-between;
}

.faviconadded.modal-header {
  justify-content: flex-start;
}

.verified-btn {
  background-color: var(--base-green) !important;
}

.verified-btn svg {
  color: green;
}

.faviconadded.modal-header img {
  margin-right: 10px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  padding: 1px;
  background: pink;
}

.usericon .row .col {
  padding: 0;
  max-width: 49%;
  flex: 0 0 49%;
}

.home-wrapper .homebar ul li p.email-verify .verify-btn {
  width: 30px;
  height: 30px;
  padding: 0px;
}

.AddTestimonialBox .testimonails-listing li svg {
  margin-right: 12px;
  font-size: 20px;
}

.AddTestimonialBox .testimonails-listing li span {
  margin-right: 5px;
}

[data-page="/organiserdashboard"] .MoneyCollectBox .moneyaddedbar,
[data-page="/organiserdashboard"] .AddParticipant .participant-avatar,
[data-page="/organiserdashboard"] .boxwithnumeric-block .addgiftcard .savebtn,
[data-page="/organiserdashboard"] .addgiftcard .togglebar,
[data-page="/organiserdashboard"] .AddParticipant .participant-avatar {
  display: none;
}

[data-page^="/organiser"] .MoneyCollectBox h4 .settingicon,
[data-page^="/organiser"] .price-collected,
[data-page^="/organiser"] .country-price,
[data-page^="/organiser"]
  .boxwithnumeric-block
  .boxwithnumeric
  .savebtn
  .btngray,
[data-page^="/organiser"] .boxwithnumeric-block .boxwithnumeric .per-person,
[data-page^="/organiser"]
  .AddParticipant
  .participant-list
  .participant-list-ul
  li,
[data-page^="/organiser"] .peoplejoined,
[data-page^="/organiser"] .AddParticipant .headryt,
[data-page^="/organiser"] .boxwithnumeric-block .boxwithnumeric .giftcard,
[data-page^="/organiser"] .AddParticipant .willsent,
[data-page^="/organiser"] .AddTestimonialBox .testimonails-listing {
  /* display: none; */
}

.allcardslist .Customcheck {
  background: var(--base-white);
  border: 1px solid var(--base-border);
  border-radius: 24px;
}

.allcardslist .CardCustomcheck.topLabel .check-detail .carddetail img {
  height: 250px;
  object-fit: contain;
}

.allcardslist .CardCustomcheck.topLabel {
  order: -1 !important;
  /* background: red !important; */
  min-width: 100%;
}

.allcardslist .CardCustomcheck.topLabel .check-detail {
  padding-top: 5px;
}

.allcardslist .CardCustomcheck:not(.topLabel) {
  order: initial !important;
}

.allcardslist .CardCustomcheck {
  display: block;
  border-radius: 12px;
  margin-bottom: 24px;
  position: relative;
  padding: 0 8px;
  flex: 0 0 50%;
}

.allcardslist .CardCustomcheck input.form-control {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0;
  border-radius: 12px;
  z-index: 2;
}

.allcardslist .CardCustomcheck .check-detail {
  display: block;
  padding: 0px;
  border: 1px solid #f2f4f7;
  border-radius: 12px;
}

.allcardslist .CardCustomcheck input.form-control:checked ~ .check-detail {
  border: 1px solid #f63d68;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fee4e2;
  background-color: var(--lightgreen-bg);
}

.allcardslist .CardCustomcheck span.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border-radius: 24px;
}

.allcardslist .CardCustomcheck input.form-control:checked ~ .checkmark {
  background: transparent;
}

.allcardslist
  .CardCustomcheck
  input.form-control:checked
  ~ .check-detail
  .cardheader
  .circle {
  background: var(--teritory-bg);
  border: 1px solid var(--base00);
  box-shadow: 0px 2px 6px rgba(227, 27, 84, 0.4);
}

.allcardslist .CardCustomcheck .check-detail .cardheader .circle span.dot {
  background: transparent;
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
}

.allcardslist .CardCustomcheck .check-detail .cardheader {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #344054;
  padding-bottom: 8px;
  border-bottom: 1px solid #f2f4f7;
  margin-bottom: 8px;
}

.allcardslist .CardCustomcheck .check-detail .cardheaderbar .viewbtn {
  background: transparent;
  padding: 0;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #c01048;
  position: relative;
  z-index: 3;
}

.banner-content-span {
  color: #e51b56;
}

.giftcardmodal .modal-body {
  padding-top: 0;
  max-height: 600px;
  overflow: scroll;
}

.giftcardmodal .modal-body .modalgiftcard-wrapper .denominatebar h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #000000;
}

.danger-checkbar span {
  background: rgb(227 63 84 / 35%) !important;
  color: #e44054 !important;
}

.danger-checkbar span svg {
  fill: red !important;
}

.modalaccordion .accordion-body {
  padding-left: 12px;
  padding-right: 12px;
}

.modalaccordion .accordion-body ul li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 15px;
}

.modalaccordion .accordion-body ul li::after {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background: #212529;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0px, 10px);
}

.modalaccordion .accordion-body ul {
  padding-left: 12px;
}

.modalaccordion {
  border: none;
  position: relative;
}

.modalaccordion .accordion-item {
  border: none;
}

.modalaccordion .accordion-item .accordion-button {
  padding: 12px;
  font-weight: 500;
  box-shadow: none !important;
  outline: none !important;
  background: transparent !important;
  border: none !important;
  color: #000000 !important;
}

.modalaccordion .accordion-item .accordion-button::after {
  width: 26px;
  height: 26px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: absolute;
  right: 0;
  background-size: 16px;
  background-position: center;
  border-radius: 4px;
  border: 2px solid #000000;
  background-image: url("../../public/images/plus.png") !important;
}

.modalaccordion .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("../../public/images/minus.png") !important;
}

.giftcardmodal .modal-body .modalgiftcard-wrapper .denominatebar ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.giftcardmodal .modal-body .modalgiftcard-wrapper .denominatebar ul li {
  margin-right: 16px;
}

.giftcardmodal .modal-body .modalgiftcard-wrapper .gift-img {
  width: 100%;
  margin-bottom: 16px;
}

.giftcardmodal .modal-body .modalgiftcard-wrapper .gift-img img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 20px;
}

.giftcardmodal .modal-body .modalgiftcard-wrapper .denominatebar {
  background: rgb(232 64 78 / 10%);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
}

.allcardslist .CardCustomcheck .check-detail .cardheaderbar {
  padding: 8px;
}

.allcardslist .CardCustomcheck .check-detail .cardheader .circle {
  width: 16px;
  flex: 0 0 16px;
  height: 16px;
  background: var(--base-white);
  border: 1px solid var(--base-border);
  border-radius: 26px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-inline .col {
  padding: 0 5px;
}

.col-inline {
  margin: 0 -15px;
}

.SelectGiftCard h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--base02);
  margin-bottom: 5px;
}

.SelectGiftCard p {
  margin-bottom: 24px;
}

.allcardslist
  .CardCustomcheck
  input.form-control:checked
  ~ .check-detail
  .cardheader
  .circle
  span.dot {
  background: var(--base00);
}

.allcardslist .CardCustomcheck .check-detail .cardheader .circlecheck img {
  width: 38px;
  height: 38px;
}

.allcardslist .CardCustomcheck .check-detail .cardheader .circlecheck {
  display: none;
  margin-right: 12px;
}

.allcardslist .CardCustomcheck .check-detail .carddetail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.allcardslist {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  margin: 0 -8px;
}

.allcardslist .CardCustomcheck .check-detail .carddetail img {
  margin-right: 0px;
  border-radius: 12px;
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.allcardslist .CardCustomcheck .check-detail .carddetail p {
  font-family: "Kite One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: var(--base03);
}

.Participant-Listall {
  min-height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0px;
}

/* ------------new cards-------------- */
.user_name {
  display: flex;
  align-items: center;
  column-gap: 9px;
  margin-bottom: 16px;
}

.avatar-image {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
}

.avatar-image img {
  width: 24px;
  height: 24px;
}

.card_body {
  padding: 20px 15px;
  border-radius: 24px;
  background: rgba(16, 24, 40, 0);
  background-image: url("../../public/images/newcardtop.png");
  background-repeat: no-repeat;
  background-position: top right;
}

.bg_sky {
  background-color: rgba(132, 224, 248, 1);
}

.bg_mustard {
  background-color: rgba(255, 196, 112, 1);
}

.bg_pink {
  background-color: var(--base00);
}

.bg_lightpink {
  background-color: var(--error-border);
}

.bg_grey {
  background-color: var(--base-border);
}

.bg_red {
  background-color: var(--base-error);
}

.user_name h4 {
  font-size: 16px;
  font-weight: 400 !important;
  font-family: "Poppins";
  margin-bottom: 0 !important;
}

.user_detail p {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(52, 64, 84, 1);
  text-align: start;
}

.colorful_cards .carousel.slide {
  padding: 0 10px;
}

.time_date {
  margin-top: 10px;
  text-align: end;
}

.colorful_cards .carousel-indicators {
  display: none;
}

.colorful_cards .carousel-control-next-icon,
.colorful_cards .carousel-control-prev-icon {
  background-color: grey;
  border-radius: 50%;
  background-size: 15px 15px;
}

.colorful_cards .carousel-control-next {
  right: -20px;
  opacity: 1;
}

.colorful_cards .carousel-control-prev {
  left: -20px;
  opacity: 1;
}

.btn-none .carousel-control-next {
  right: -20px;
  opacity: 0;
}

.btn-none .carousel-control-prev {
  left: -20px;
  opacity: 0;
}

.simplebtn {
  background: transparent;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--base-border);
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--base03);
}

.simplebtnlink {
  background: transparent;
  border: none;
  color: var(--bs-link-color);
}

/* 
@supports (-webkit-touch-callout: none) {
  @media (max-width: 767px) {
    .Participant-Listall {
      padding-bottom: 80px;
    }
  }
} */

.btnlogout {
  margin-bottom: 80px;
}

a.see-more-contributors {
  margin: 0px 15px !important;
  color: var(--base00);
  cursor: pointer;
}

.btnlogout button.btn-pink {
  background-color: var(--base00) !important;
  border: 1px solid var(--base00) !important;
  width: auto !important;
  height: 48px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  cursor: pointer;
  color: var(--base-white) !important;
  font-size: 16px !important;
  line-height: 16px !important;
  outline: none !important;
  font-weight: 500 !important;
  text-decoration: none;
  transition: 0.1s ease-in;
  padding: 12px 24px;
}

.Participant-Listall ul.participant-list-ul li .detail-participant h4 {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: var(--base03);
}

.recipient-terms-condition {
  width: 100% !important;
  padding: 0px;
}

.recipient-terms-condition .accordion-header {
  padding: 0px 10px;
}

.notebox {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.videoRecording {
  position: relative;
}

.videoRecording video {
  width: 23.438rem;
  height: 23.438rem;
  object-fit: cover;
}

.videoRecording button svg {
  font-size: 30px;
}

.nowish-illustration {
  text-align: center;
  padding-top: 20px;
}

.nowish-illustration img {
  margin-bottom: 20px;
}

.TotalContribute .crossbar {
  margin-bottom: 20px;
}

.TotalContribute .crossbar a {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  color: #ffffff !important;
  border-radius: 50px;
}

.TotalContribute .contri-text {
  text-align: center;
}

.TotalContribute .contri-text h5 {
  margin: 0 0 0 0;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #ffffff;
}

.TotalContribute .contri-text h3 {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.TotalContribute .peoplesbar h5 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #344054;
}

.TotalContribute .peoplesbar h6 {
  color: #344054;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.TotalContribute .titlebar {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}

.TotalContribute .Home-component .allcardslist {
  text-align: center;
  border: 0.85px solid #d0d5dd;
  box-shadow: 0px 10.228687286376953px 13.638250350952148px -3.409562587738037px
    #10182814;
  border-radius: 24px;
  margin-bottom: 24px;
}

.TotalContribute .Home-component .allcardslist img {
  width: 100%;
}

.TotalContribute .Home-component .allcardslist h4 {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  column-gap: 10px;
  color: #c01048;
}

.nowish-illustration h4 {
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
  word-wrap: break-word;
}

.seeall {
  margin-top: 40px;
  text-align: center;
}

.faq-list {
  margin-bottom: 20px;
}

.faq-list h4 {
  font-size: 18px !important;
  margin-bottom: 5px;
}

.faq-list p {
  margin-top: 0 !important;
  font-size: 15px !important;
}

.recipient-detail-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.video-with-share-btn {
  padding: 0px 0 0 0 !important;
  position: relative;
}

.video-with-share-btn .btnshare-video {
  position: absolute;
  top: 0;
  right: 0;
}

.video-with-share-btn .btnshare-video .bntshare {
  width: 50px;
  height: 50px;
  border: 1px solid rgb(0 0 0 / 59%);
  background: rgb(0 0 0 / 59%);
  color: red;
  border-radius: 0px 0px 5px 4px;
  font-size: 21px;
}

.recipient-detail-box .userdetail {
  padding-right: 10px;
  flex: 0 0 60px;
  max-width: 60px;
  border-radius: 50%;
}

.recipient-detail-box .userdetail img {
  height: 50px;
  object-fit: cover;
  width: 50px;
  border-radius: 50%;
}

.recipient-detail-box .namebar h4 {
  font-size: 16px;
  line-height: 1;
}

.recipient-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
}

.faq-list h4 {
  margin-bottom: 5px;
}

body.media-viewer .body-wrapper .videoRecording .duration {
  position: fixed;
  color: #ffffff;
  text-shadow: 1px 1px 5px #000000;
  bottom: 160px;
  font-size: 20px;
  left: 50%;
  transform: translate(-50%, 0px);
}

.videoRecordingbar .videoshow {
  margin-bottom: 24px;
  text-align: center;
}

.videoRecordingbar .durationend-bar {
  margin-bottom: 20px;
  padding: 0 24px;
}

.videoRecordingbar {
  height: 100%;
  position: relative;
  padding: 80px 0 0px 0;
}

.videoRecordingbar .durationend-bar p {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ffffff;
}

.videoRecording .startcam,
.videoRecording .stopcam,
.videoRecording .switchcam {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--base00);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 0px;
  line-height: 1;
}

.switchcam {
  position: fixed;
  bottom: 70px;
  right: 24px;
  transform: translate(0px, 0px);
}

body.media-viewer .body-wrapper {
  padding: 0;
}

body.media-viewer .body-wrapper .videoRecording {
  min-height: 100dvh;
  margin: 0;
}

.space-24 {
  padding: 0 24px;
}

.downloadbtn {
  position: absolute;
  bottom: 0;
  left: 24px;
  transform: translate(0px, 0px);
}

button.startcam,
button.stopcam {
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translate(-50%, 0);
}

.Participant-Listall ul.participant-list-ul li .detail-participant p {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: left;
  color: var(--base01);
}

.testimonial-tabs .testimonial-tabs-content {
  padding-bottom: 90px;
}

.usergiftlist {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.usergiftlist .userdetail {
  padding: 0 0 0 0 !important;
  width: 100%;
  margin-left: 10px;
}

.usergiftlist .userdetail h4 {
  width: 100%;
}

.usergiftlist img {
  flex: 0 0 94px;
  max-width: 94px;
}

.usergiftlist .userdetail h6.date {
  margin: 0;
}

.Participant-Listall ul.participant-list-ul li .btnbar {
  display: flex;
  align-items: center;
}

.giftcard-modal ul.EventPartially-list li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.Participant-Listall ul.participant-list-ul li .btnbar .delbtn {
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.testimonial-tabs-content {
  padding-bottom: 160px;
}

.Participant-Listall ul.participant-list-ul li .btnbar button {
  margin-left: 20px;
}

.participant-list-ul li.revokeli {
  display: block;
}

.participant-list-ul li.revokeli {
  display: block;
}

.participant-list-ul li.revokeli .revokebar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.participant-list-ul li.revokeli .revokebar a.btngray {
  max-width: 123px;
  padding: 6px;
  height: auto;
  font-size: 12px;
}

.participant-list-ul li.revokeli .revokebar a.text-btn {
  margin-right: 30px;
}

.participant-list-ul .li[disabled] .detail-participant {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-pink svg {
  margin-right: 8px;
}

.Createevent-modal .modal-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--base03);
}

.btn-pink svg {
  margin-right: 8px;
}

.Createevent-modal .modal-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--base03);
}

.Createevent-modal .modal-body form .shareonwtsapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Createevent-modal .modal-body form .shareonwtsapp p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
}

.Createevent-modal .modal-body form .shareonwtsapp .wtsapp {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

.Createevent-modal .modal-body form .btnbar p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base01);
  margin-top: 8px;
}

.userdetail {
  padding-right: 130px;
}

.help-block.help-block-error {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base-error);
  margin-top: 3px;
}

.sidebar {
  position: fixed;
  right: 0;
  width: 100%;
  background-color: rgb(0 0 0 / 75%);
  height: 100vh;
  top: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.2s ease;
}

.sidebarin {
  padding: 20px;
  background: var(--base-white);
  box-shadow: 0px 20px 24px -4px rgb(16 24 40 / 8%),
    0px 8px 8px -4px rgb(16 24 40 / 3%);
  border-radius: 32px 0px 0px 32px;
  max-width: 90%;
  margin: 0 0 0 auto;
  position: relative;
  transition: 0.4s ease;
  overflow-x: hidden;
  overflow-y: scroll;
  right: -100%;
}

.sidebar.active .sidebarin {
  right: 0;
}

.sidebarin button.closesidebar {
  background: transparent !important;
  padding: 0 !important;
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto !important;
  height: auto !important;
}

.sidebar.hidden {
  opacity: 0;
  visibility: hidden;
}

.Helpbar .Number-email-detail {
  display: block !important;
}

.sidebarin h4.titleevent {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--form-input);
}

.Helpbar .Number-email-detail li button {
  width: 100% !important;
  height: 100% !important;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%) !important;
  border-radius: 8px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  flex: 0 0 32%;
  max-width: 32%;
}

.SocialMedia ul.Social-bar {
  justify-content: flex-start;
}

.sidebar.active {
  opacity: 1;
  visibility: visible;
}

body[data-page="/eventcancleunsuccessful"] .Headerbar {
  display: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease !important;
  transform: translate(0, 100%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0%) !important;
  display: flex;
  align-items: flex-end;
  margin: 0 auto !important;
  height: 100%;
}

.cookie-modal-full .modal-dialog {
  min-width: 95vw !important;
}

.EventCancleUnsuccessful {
  height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.EventCancleUnsuccessful .centerbar {
  width: 100%;
}

.EventCancleUnsuccessful .centerbar .Success-bg {
  margin-bottom: 45px;
}

.EventCancleUnsuccessful .centerbar p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.EventCancleUnsuccessful .bottombar {
  width: 100%;
}

.EventCancleUnsuccessful .bottombar .btn-pink {
  margin-bottom: 16px;
}

.AllGiftCard {
  width: 100%;
  text-align: center;
}

.AllGiftCard img {
  width: 100%;
}

.datetimepicker button.btngray {
  padding: 12px 5px;
  font-size: 14px;
}

.Contributionbox .accordion-item {
  margin-bottom: 20px;
}

.RecepientPartially .OpenGiftCard.allcards {
  height: auto;
}

.invitebar .invitebar-in {
  position: relative;
  padding: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: flex-start;
}

.resentbtn,
.how-attempt {
  display: none;
}

.invitebar .invitebar-in .labelbar {
  position: absolute;
  top: -15px;
  background: #ceeab0;
  border: 5px solid var(--base-white);
  border-radius: 6px;
  padding: 2px 9px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--base03);
  left: 10px;
}

.invitebar .invitebar-in .invite-icon {
  margin-right: 16px;
  flex: 0 0 24px;
}

.invitebar .invitebar-in .invite-icon img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.invitebar .invitebar-in .invitecontent {
  margin: 0;
}

.invitebar .invitebar-in .invitecontent h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--base03);
  margin: 0;
}

.invitebar .invitebar-in .invitecontent p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: var(--base01);
}

.invitebar .invitebar-in .invitecontent ul.social-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}

.invitebar .invitebar-in .invitecontent ul.social-bar li {
  flex: 0 0 24px;
  margin-right: 11px;
}

.invitebar .invitebar-in .invitecontent ul.social-bar li img {
  width: 100%;
}

.invitebar .invitebar-in .invitecontent ul.social-bar li {
  flex: 0 0 24px;
  margin-right: 11px;
}

.invitebar .invitebar-in .invitecontent ul.social-bar li img {
  width: 100%;
}

.AllGiftCard,
.giftcard {
  position: relative;
  overflow: hidden;
}

.AllGiftCard::before,
.giftcard::before {
  content: "";
  display: block;
  height: 100%;
  border-radius: 0;
  transform: rotate(30deg);
}

.loaderbar {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  background: #fff;
}

body:not(.show-app-loader) #mainAppLoader {
  display: none;
}

.audio-recorder {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  width: 100% !important;
  display: none !important;
  padding: 0 !important;
  height: 100%;
  text-align: center;
}

.audio-recorder img.audio-recorder-mic {
  /* display: none; */
  height: 72px;
  color: #ffffff !important;
  filter: invert(1);
  margin-bottom: 24px;
  cursor: pointer;
}

.audio-recorder span.audio-recorder-timer {
  display: block !important;
  margin: 0 !important;
  width: 100% !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Base/White */
  color: #ffffff;
  margin-bottom: 30px !important;
}

body[data-page^="/audio"] .max-width-window,
body[data-page="/addmedia"] .max-width-window {
  background: #000000;
}

.AddTestimonial .add-review-bar .inputbar input.form-control {
  padding: 12px 40px 12px 12px;
}

.when-no-wish {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

[data-page^="/addtestimonial/"] .videoRecording {
  padding: 0;
  height: calc(100vh - 70px);
  margin: 0 -15px;
}

.videoRecording .render-actions__ActionsWrapper-dp6lnv-0 {
  top: inherit !important;
  bottom: 40px;
}

[data-page^="/addtestimonial/"]
  .body-wrapper
  .disconnected-view__SVGWrapper-sc-1hdjm8z-0 {
  display: none;
}

.add-review-bar .inputbar .btnsend {
  padding: 0 !important;
}

body[data-page^="/audio"] .max-width-window .Headerbar,
body[data-page="/addmedia"] .max-width-window .Headerbar {
  display: none;
}

body[data-page^="/audio"] .max-width-window .body-wrapper {
  height: 100vh;
  padding: 56px 24px;
}

body[data-page="/addmedia"] .max-width-window .body-wrapper {
  height: 100vh;
  padding: 0px 0px;
}

.audio-record-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.audio-record-screen .crossbar,
.addmedia-wrapper .crossbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--base-white);
  border-radius: 50%;
  padding: 4px 8px;
}

.crossbar a {
  color: var(--base02);
}

.addmedia-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
}

.addmedia-wrapper .media-message-box {
  padding: 24px;
}

.addmedia-wrapper .media-message-box .form-control {
  margin-bottom: 24px;
}

.addmedia-wrapper .crossbar {
  padding: 0 24px;
  margin-bottom: 24px;
}

.addmedia-wrapper .media-wraper {
  position: relative;
  height: 340px;
}

.addmedia-wrapper .media-wraper img {
  object-fit: contain;
}

.addmedia-wrapper .media-wraper img,
.addmedia-wrapper .media-wraper iframe,
.addmedia-wrapper .media-wraper video,
.addmedia-wrapper .media-wraper audio {
  height: 100%;
  width: 100%;
  border-radius: 0;
}

.audio-record-screen .middlebar img {
  margin-bottom: 24px;
}

.audio-record-screen .middlebar p.timerbar {
  text-align: center;
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}

.audio-record-screen .bottomabr {
  width: 100%;
}

.audio-record-screen .bottomabr button.btn-pink {
  margin-bottom: 12px;
}

.audio-record-screen .bottomabr .Controls {
  text-align: center;
  margin-bottom: 50px;
}

.audio-record-screen .bottomabr .Controls button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.audio-record-screen .crossbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
}

.audio-recorder span.audio-recorder-status {
  margin: 0 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  justify-content: center;
}

.recording {
  opacity: 0;
}

.audio-recorder img.audio-recorder-options {
  color: #ffffff;
  width: 102px !important;
  height: 102px !important;
  margin: 0 !important;
  background: #f63d68 !important;
  padding: 12px;
  border-radius: 50%;
  object-fit: contain !important;
}

.Home-component.EventPartially .sparkles-bg {
  z-index: -1;
}

.react-timekeeper__clock-wrapper div[class^="css"] {
  text-align: left;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
  opacity: 0.6;
  cursor: not-allowed;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active {
  background-color: var(--base00) !important;
  color: #fff !important;
}

body.media-viewer {
  background: #000;
}

body.media-viewer .Headerbar {
  display: none;
}

.videoRecording .crossbar {
  align-items: center;
  display: flex;
  font-size: 30px;
  height: 50px;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 15px;
  width: 50px;
  z-index: 100;
  background: #ffffff;
  border-radius: 50%;
}

.addmedia-wrapper .crossbar {
  position: absolute;
  right: 25px;
  z-index: 999;
  top: 35px;
  background: var(--base-white);
  border-radius: 50%;
  padding: 5px 8px;
}

.react-calendar {
  width: 100% !important;
}

.country-price ul.perviewlist {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.country-price h6.allusers-text {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.country-price ul.perviewlist li {
  margin-left: -15px;
}

.country-price ul.perviewlist li:first-child {
  margin: 0;
}

.country-price h6.allusers-text {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.fixamountpopup .amount-type input,
.fixamountpopup .perperson {
  opacity: 0.3;
}

.fixamountpopup.opacity-full .amount-type input,
.fixamountpopup.opacity-full .perperson {
  opacity: 1;
}

.dateaddedbar div[role="button"] {
  width: 70px !important;
  height: 70px !important;
  margin-bottom: 15px !important;
}

.fixamountpopup .modal-header {
  border: none;
  font-weight: 600;
  font-size: 16px;
  border-radius: 20px 20px 0 0;
}

.dateaddedbar h4 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #344054;
}

.dateaddedbar {
  margin-bottom: 20px;
}

.dateaddedbar p {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.dateadded-popup .modal-body {
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.dateadded-popup .modal-footer {
  border: none;
}

.profimnew {
  border-radius: 50% !important;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.w-100 {
  width: 100% !important;
}

.rmdp-input {
  z-index: 999 !important;
}

.rmdp-input,
.datepickerbar {
  text-transform: normal;
  width: 100% !important;
  background: var(--base-white);
  color: var(--form-input);
  border: 1px solid var(--base-border) !important;
  border-radius: 8px !important;
  padding: 12px 14px 12px 14px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  height: 50px !important;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* z-index: 999 !important; */
}

.rmdp-input:focus,
.rmdp-input:focus-visible {
  background: var(--base-white) !important;
  border: 1px solid #363f72 !important;
  box-shadow: 0px 1px 2px rgb(62 71 132 / 5%), 0px 0px 0px 4px #eaecf5 !important;
}

.rmdp-container {
  width: 100% !important;
}

.datepickerbar input::-webkit-date-and-time-value {
  text-align: left !important;
}

.datepickerbar::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 50% !important;
  padding: 0px !important;
  left: calc(100% - 30px) !important;
  transform: translate(0px, -50%);
  width: 20px !important;
  height: 20px !important;
}

/* Langing page css start from here  */
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--base00);
}

.landingpage {
  background: var(--bg-light-lg);
}

.feature-text {
  color: #e41b55;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 600;
}

.feature-listing {
  padding-left: 15px;
}

.feature-listing-mobile-block ul.feature-listing {
  padding: 0 0 0 0px !important;
  display: block !important;
}

.feature-listing li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #667085;
  line-height: 24px;
}

.feature-listing li b {
  color: #101829;
}

.feature-listing li:last-child {
  margin-bottom: 0;
}

.LandingHeader {
  position: sticky;
  top: 0;
  z-index: 2;
}

.LandingHeader nav.navbar {
  padding: 24px 0;
}

.LandingHeader nav.navbar .navbar-brand {
  padding: 0;
}

.LandingHeader nav.navbar {
  background: var(--bg-light-lg);
  border-bottom: 1px solid #ffccd6;
}

.LandingHeader nav.navbar .navbar-nav a {
  padding: 0;
  margin-left: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.landing-right-bar {
  position: sticky;
  top: 0;
}

.LandingHeader nav.navbar .navbar-nav a.active,
.LandingHeader nav.navbar .navbar-nav a:hover {
  color: var(--base00);
}

.banner-content {
  padding-top: 35px;
  max-width: 480px;
  margin: 0 auto 0 0;
}

.banner-content h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  /* line-height: 72px; */
  /* letter-spacing: -0.02em; */
  color: #101828;
  margin-bottom: 24px;
}

.banner-content p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #667085;
}

.landing-right-bar img {
  width: 100%;
}

.padding-right-90 {
  padding-right: 30px;
}

.banner-shape {
  text-align: right;
  padding-right: 30px;
  padding-bottom: 0px;
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.banner-shape a.btn-pink {
  width: auto;
  padding: 20px 40px;
  height: auto;
  font-size: 16px !important;
}

.howwework-title {
  margin-bottom: 40px;
}

.howwework-title h3,
.landing-call-component .GroupGiftBox h4 {
  font-weight: 600;
  font-size: 25px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
  margin-bottom: 20px;
}

.howwework-title p,
.landing-call-component .GroupGiftBox p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #667085;
}

.landing-call-component .GroupGiftBox p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.stepping-bar .iframe-div {
  height: 400px;
}

.stepping-bar ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.stepping-bar ul li:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 120px;
  line-height: 180px;
  display: flex;
  align-items: center;
  color: #fea3b4;
  opacity: 0.3;
  margin-right: 50px;
  flex: 0 0 70px;
  max-width: 70px;
}

.stepping-bar ul li img {
  margin-bottom: 16px;
}

.stepping-bar ul li h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #f63d68;
  margin-bottom: 0px;
}

.stepping-bar ul li p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.stepping-bar ul:nth-child(2) {
  margin: 20px 0;
}

.landing-call-component .landing-align-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.landing-call-component .landing-align-flex .greengift-cards {
  width: calc(50% - 12px);
}

.landing-call-component .Footer {
  margin-top: 40px;
}

.about-content {
  margin-bottom: 20px !important;
}

.Recepient .AddKudos ul.perviewlist {
  justify-content: center;
}

.Recepient .AddKudos ul.perviewlist img {
  margin: 0;
}

.timerzone {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.banner-side-slider {
  background-image: url("../../public/images/phone-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-side-slider {
  width: 331px;
  overflow: hidden;
  height: 665px;
  margin: 10px 0 0 auto;
  padding: 15px 15px 18px 18px;
}

.timerzone li {
  margin-right: 10px;
  font-size: 12px;
}

.Recepient .AddKudos h6.allusers-text {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #344054;
}

.AddKudos .addkudos-form {
  padding-top: 12px;
  border-top: 1px solid #ceeab0;
  margin-top: 12px !important;
}

.AddKudos .addkudos-form form {
  justify-content: center;
}

.AddKudos .addkudos-form .viewwisher {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.rec-listing ul.perviewlist li img {
  width: 24px;
  height: 24px;
}

.rec-listing ul.perviewlist li {
  margin: 0 8px 0 0 !important;
}

.Recepient .testimonialbar .testimonial-tabs-content {
  padding-bottom: 24px;
}

.mob-right-bar {
  display: none;
}

/* ----------------terms--------------- */
.terms p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  margin: 20px 0;
}

.terms h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #f63d68;
  margin-bottom: 0px;
}

.terms ol {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
  padding-left: 40px;
}

.terms ol li {
  margin: 10px 0;
}

/* -----------------------------New changes---------------------- */
.header_celebration_badge {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.margin-minus {
  margin: 0 -15px;
}

.margin-minus .col {
  padding: 0 3.5px;
}

.head-with-badge {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header_celebration_badge .badge_green {
  background: var(--states-bg) !important;
  padding: 8px 12px;
  color: green;
}

.header_celebration_badge .badge_red {
  background: hsla(343, 79%, 50%, 0.302) !important;
  padding: 8px 12px;
  color: #e31b54;
}

@media (max-width: 1199px) {
  .Landing-block .container {
    max-width: 100%;
    padding: 0 5%;
  }

  .banner-shape img {
    max-width: 40%;
  }
}

@media (max-width: 991px) {
  .blog-content {
    padding-top: 55px !important;
  }

  .banner-side-slider {
    margin: 0 auto;
  }

  .LandingHeader nav.navbar button.navbar-toggler {
    border: none;
    padding: 0;
    outline: none;
    box-shadow: none;
  }

  .mob-right-bar {
    display: block;
  }

  .mob-right-bar {
    text-align: center;
    margin: 20px 0 20px 0;
  }

  .banner-shape a.btn-pink {
    width: auto;
    padding: 15px 30px;
    height: auto;
    font-size: 18px !important;
  }

  .LandingHeader nav.navbar .navbar-collapse {
    background: rgb(229 64 84 / 6%);
    padding: 10px;
    margin-top: 20px;
  }

  .LandingHeader nav.navbar .navbar-collapse a {
    margin: 0;
    padding: 10px;
  }

  .banner-shape img,
  .landing-right-bar {
    display: none;
  }

  .banner-content {
    padding-top: 25px;
    max-width: 100%;
    margin: 0 auto 0 0;
  }

  .banner-shape {
    text-align: right;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  label.form-label {
    display: block;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--base03);
    margin-bottom: 5px;
  }

  .margin-minus .col {
    padding: 0 3.5px;
  }

  .blogs-box h4 {
    font-size: 22px;
    line-height: 30px;
  }

  .border-dooted-top {
    margin-right: 0 !important;
  }

  .modal-dialog {
    max-width: 95%;
  }

  .Landing-block .container {
    padding: 0 5%;
  }

  .Landing-block .container .row {
    margin: 0;
  }

  .LandingHeader nav.navbar button.navbar-toggler {
    border: none;
    padding: 0;
    outline: none;
    box-shadow: none;
  }

  .banner-content h1 {
    font-size: 35px;
    /* line-height: 52px; */
    margin-bottom: 10px;
  }

  .about-content {
    margin-bottom: 0px !important;
  }

  .banner-shape {
    padding: 20px 0 20px 0;
  }

  .stepping-bar ul li:first-child {
    font-size: 60px;
    margin-right: 20px;
    flex: 0 0 40px;
  }

  .landing-call-component .landing-align-flex {
    display: block;
  }

  .landing-call-component .landing-align-flex .greengift-cards {
    width: 100%;
  }

  .padding-right-90 {
    padding: 0;
  }
}

.gallerybar img {
  border-radius: 12px;
  transition: 0.3s ease;
  cursor: pointer;
  object-fit: cover;
}

.gifbar .modal-body {
  padding: 20px 10px !important;
  position: relative;
}

.gifbar button.btn-pink {
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto !important;
  padding: 8px 14px;
  height: 43px;
  font-size: 14px;
}

.gifbar .modal-body .scrollgif {
  height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gifbar .modal-body .scrollgif img {
  flex: 0 0 48% !important;
  max-width: 48%;
  height: auto;
  object-fit: cover;
}

.gifbar input.form-control {
  padding-right: 100px;
}

.contact-detailbar ul li a {
  color: #e63b6d;
}

.gifbar .modal-footer {
  justify-content: center !important;
  position: relative;
}

.contact-detailbar {
  margin-top: 20px;
}

.contact-detailbar ul li {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--or-after);
}

.contact-detailbar ul {
  flex-direction: column;
  display: flex;
  gap: 6px;
}

.gallerybar img:hover {
  transform: scale(1.02);
}

.gallerybar div {
  display: none !important;
}

.gallerybar div:not(:empty) {
  display: flex !important;
}

/* @media (max-width:767px){ */
.gallerybar div:first-child img:nth-child(odd) {
  height: 200px;
  object-fit: cover;
}

.gallerybar div:first-child img:nth-child(even) {
  height: 150px;
  object-fit: cover;
}

.gallerybar div:nth-child(2) img:nth-child(even) {
  height: 220px !important;
}

.gallerybar div:nth-child(2) img:nth-child(odd) {
  height: 130px !important;
}

.gallerybar div:nth-child(3) img:nth-child(even) {
  height: 240px !important;
}

.gallerybar div:nth-child(3) img:nth-child(odd) {
  height: 180px !important;
}

.masonry-modal .modal-dialog {
}

.masonry-modal .modal-dialog .modal-header {
  padding: 0;
  border: none;
}

.masonry-modal .modal-dialog .modal-header button.btn-close {
  opacity: 1;
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 0;
  z-index: 2;
  font-size: 20px;
  filter: invert(1);
}

.masonry-modal .modal-dialog .modal-body {
  padding: 0;
  border-radius: 10px 10px 0 0 !important;
  position: relative;
}

.masonry-modal .modal-dialog .modal-content {
  border-radius: 10px 10px 0 0 !important;
}

.masonry-modal .modal-dialog .modal-body h3 {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  z-index: 2;
  text-align: center;
  background: #231f1f4a;
  color: #ffffff;
  font-size: 20px;
}

.masonry-modal .modal-dialog .modal-body img {
  border-radius: 10px 10px 0 0;
}

/* } */

.bottom-to-top button {
  position: fixed;
  background-color: var(--btn-pinkhover);
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 50%;
  bottom: 20px;
  right: 20px;
  display: none;
  align-items: center;
  justify-content: center;
}

.btnshow button {
  display: flex;
}

@media (min-width: 767px) {
  .datetimepicker button.btngray {
    padding: 12px 5px;
    font-size: 14px;
  }

  .noheader .onboardbar {
    max-width: 420px;
    margin: 0 auto;
    box-shadow: 0px 0px 100px rgb(0 0 0 / 5%);
    padding: 70px 20px 20px 20px;
  }

  .sidebarin {
    max-width: 380px;
    margin: 0 auto;
    margin-left: calc(calc(50% - 220px) + 60px);
  }

  .max-width-window {
    max-width: 440px;
    margin: 0 auto;
    box-shadow: 0px 0px 100px rgb(0 0 0 / 5%);
    padding: 0px 0px 0px 0px;
    min-height: 100vh;
  }
}

@media (max-width: 574px) {
  .landing-call-component .Footer {
    margin-left: -15px;
    margin-right: -15px;
  }

  .Headerbar {
    width: 100%;
    padding: 20px 15px 20px 15px;
    position: relative;
  }

  .btngray,
  .btn-pink {
    font-size: 13px;
    padding: 12px;
    line-height: 24px;
  }

  .homebar {
    padding: 20px 15px 20px 15px;
    position: relative;
  }

  .home-wrapper .homebar ul li.logobar p {
    font-size: 14px;
  }

  .FaqTitle h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--base-white);
    margin-bottom: 16px;
  }

  .FaqTitle p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .FaqTitle {
    text-align: center;
    margin-bottom: 30px;
  }

  .FaqAccordion .accordion .accordion-item .accordion-button {
    font-size: 16px;
    line-height: 24px;
  }

  .FaqAccordion .accordion .accordion-item {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .Home-component .teamsup {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .Home-component .btnsubmit {
    margin-top: 24px;
  }

  .Home-component {
    padding: 20px;
    margin-top: 20px;
    position: relative;
  }

  .Headerbar {
    width: 100%;
    padding: 20px 15px 15px 15px;
    position: relative;
  }

  .noheader .onboardbar .onboard-head .logobar {
    text-align: center;
    margin-bottom: 40px;
  }

  .body-wrapper {
    padding: 10px 15px 10px 15px;
    position: relative;
  }

  .btnskip,
  .gbtn {
    font-size: 14px;
    line-height: 24px;
  }

  .otpinputs .otpbar input {
    cursor: pointer;
    width: 100% !important;
    height: auto;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
  }

  .loginform h4.form-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--base03);
    margin-bottom: 20px;
  }

  .datetimepicker button.btngray {
    padding: 12px 4px;
    font-size: 12px !important;
    line-height: 16px;
  }

  .btngray,
  .btn-pink {
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .noheader .onboardbar {
    padding-top: 0px !important;
  }

  .noheader .onboardbar .onboard-head .peoplegroup {
    margin-bottom: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .noheader .onboardbar .onboard-head .peoplegroup img {
    width: 80% !important;
  }

  .noheader .onboardbar .onboard-head .teamup-content h6 {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: var(--base02);
    margin-bottom: 10px;
    text-align: left;
  }

  .Footer {
    background: var(--base03);
    padding: 30px 15px;
    position: relative;
  }

  .Testimonial-block .userdetail h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--form-input);
    margin: 0;
  }

  .uploadmedia {
    padding: 18px 25px;
  }

  .Event-addbar {
    padding: 12px 14px;
  }
}

@media (max-width: 440px) {
  .Event-addbar .eventleft svg {
    margin-right: 10px;
  }

  .Createevent-modal .modal-body {
    padding-top: 0;
    padding: 0 15px 30px 15px;
  }

  .Createevent-modal .modal-header {
    border: none;
    padding: 30px 15px 30px 15px;
  }

  .sidebarin {
    max-width: 95%;
  }

  .Helpbar ul.Number-email-detail li p {
    flex: 0 0 64%;
  }

  .Helpbar .Number-email-detail li button {
    flex: 0 0 36%;
    max-width: 36%;
  }

  .Organiser h4 {
    font-size: 24px;
    line-height: 30px;
  }

  .invitecontent p {
    font-size: 18px;
    line-height: 28px;
  }

  .titleevent {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .error-line {
    font-size: 10px;
    line-height: 16px;
  }

  .rangebar img {
    max-width: 70%;
  }

  .successbar {
    text-align: center;
    padding: 0px 0 20px 0;
  }

  .Testimonial-block .userdetail h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--form-input);
    margin: 0;
  }

  .Customcheckbar .rowbar {
    padding: 0 0;
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 0 0 29.33%;
    max-width: 29.33%;
  }

  .Footer {
    background: var(--base03);
    padding: 30px 15px;
    position: relative;
  }

  .StatesListing {
    padding: 30px 15px;
  }

  .home-wrapper .homebar ul li.logobar p {
    font-size: 14px;
  }

  .FaqAccordion .accordion .accordion-item .accordion-button {
    font-size: 16px;
    line-height: 24px;
  }

  .StatesListing .EventStates .statesbar h2 {
    font-size: 40px;
    line-height: 48px;
  }

  .StatesListing .EventStates .statesbar h6 {
    font-size: 16px;
    line-height: 24px;
  }

  .StatesListing .EventStates {
    padding: 30px 15px;
  }

  .homebar {
    padding: 20px 15px 20px 15px;
    position: relative;
  }

  .home-wrapper .homebar ul li.logobar p {
    font-size: 14px;
  }

  .FaqTitle h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--base-white);
    margin-bottom: 16px;
  }

  .Testimonials h4 {
    margin-bottom: 5px;
  }

  .Testimonials p {
    font-size: 16px;
    line-height: 24px;
  }

  .review {
    font-size: 20px;
    line-height: 28px;
  }

  .Testimonials {
    padding: 30px 15px;
    border-radius: 32px 32px 0 0;
  }

  .FaqTitle p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .FaqTitle {
    text-align: center;
    margin-bottom: 30px;
  }

  .FaqAccordion .accordion .accordion-item .accordion-button {
    font-size: 16px;
    line-height: 24px;
  }

  .FaqAccordion .accordion .accordion-item {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .Home-component .teamsup {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .Home-component .btnsubmit {
    margin-top: 24px;
  }

  .Home-component {
    padding: 20px;
    margin-top: 20px;
    position: relative;
  }

  .Headerbar {
    width: 100%;
    padding: 20px 15px 15px 15px;
    position: relative;
  }

  .noheader .onboardbar .onboard-head .logobar {
    text-align: center;
    margin-bottom: 40px;
  }

  .body-wrapper {
    padding: 10px 15px 10px 15px;
    position: relative;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .btnskip,
  .gbtn {
    font-size: 14px;
    line-height: 24px;
  }

  .otpinputs .otpbar input {
    cursor: pointer;
    width: 100% !important;
    height: auto;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
  }

  .loginform h4.form-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--base03);
    margin-bottom: 20px;
  }

  .noheader .onboardbar .onboard-head .peoplegroup {
    margin-bottom: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .noheader .onboardbar .onboard-head .peoplegroup img {
    width: 80% !important;
  }

  .noheader .onboardbar .onboard-head .teamup-content h6 {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: var(--base02);
    margin-bottom: 10px;
    text-align: left;
  }

  .Testimonials a.carousel-control-prev,
  .Testimonials a.carousel-control-next {
    width: 38px !important;
    height: 38px !important;
  }
}

@media (max-width: 360px) {
  .check-detail {
    font-size: 9px;
    line-height: 12px;
  }
}

.datepicker {
  -webkit-appearance: none !important;
}

.add-review-bar .recorded-btn {
  padding: 12px;
  z-index: -3;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
  flex-direction: column;
  justify-items: center;
  position: absolute;
  bottom: 68px;
  right: -40px;
  row-gap: 10px;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.menuopened .recorded-btn {
  opacity: 1;
  z-index: -3;
  right: 0px;
}

.menuopened .addtbns svg {
  transform: rotate(45deg);
}

.addtbns svg {
  transition: 0.4s ease-in-out;
}

.addplusbar {
  position: relative;
}

.add-review-bar .addtbns {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--base00);
  background: var(--base00);
  font-size: 30px;
  color: var(--base-white);
}

/* .add-review-bar .recorded-btn li svg {
  transform: translate(30px, 0px);
  opacity: 0;
}

.menuopened .recorded-btn button svg {
  transform: translate(0px, 0px);
  opacity: 1;
} */

/* .add-review-bar .recorded-btn li:first-child svg {
  transition: 0.7s ease-in-out;
}
.add-review-bar .recorded-btn li:nth-child(2) svg {
  transition: 0.7s ease-in-out;
}
.add-review-bar .recorded-btn li:nth-child(3) svg {
  transition: 0.7s ease;
}
.add-review-bar .recorded-btn li:nth-child(4) svg {
  transition: 0.7s ease;
} */
